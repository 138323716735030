export const tipoDocBrasilProdutor = [
    'CPF',
    'CNPJ',
    'DAP ou CAF',
    'Inscrição Estadual'
];

export const tipoDocBrasilJurado = [
    'CPF',
    'RG'
];

export const tipoDocInternacionalProdutor = [
    'Passaporte',
    'ID da Propriedade'
];

export const tipoDocInternacionalJurado = [
    'Passaporte',
];

export const servicosSanitarios = [
    'SIM',
    'SISE',
    'SIF'
];