import React from "react";

import async from "./components/Async";
import jwtDecode from "jwt-decode";

// Layouts
import AuthLayout from "./layouts/Auth";
import HomeLayout from "./layouts/Home";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import EsqueciMinhaSenha from "./pages/auth/EsqueciMinhaSenha";
import EscolhaTipoCadastro from "./pages/auth/EscolhaTipoCadastro";
import CadastroProdutor from "./pages/auth/CadastroProdutor";
import CadastroJurado from "./pages/auth/CadastroJurado";
import Page404 from "./pages/auth/Page404";
import AlteracaoSenha from "./pages/auth/AlteracaoSenha";
import ConfirmacaoEmail from "./pages/auth/ConfirmacaoEmail";

// Dashboard components
const Dashboard = async(() => import("./pages/dashboards"));

//Inscricoes components
const ListagemInscricoes = async(() => import("./pages/inscricoes/List"));
const NovoInscricao = async(() => import("./pages/inscricoes/New"));
const DetalheInscricao = async(() => import("./pages/inscricoes/Detail"));
const FichaInscricaoImpressao = async(() => import("./pages/inscricoes/FichaInscricaoImpressao"));
const CertificadoPremiacaoInscricaoImpressao = async(() => import("./pages/inscricoes/certificados/sebrae/CertificadoPremiacaoInscricaoImpressao"));
const RelatorioDesempenhoProdutoAvaliado = async(() => import("./pages/inscricoes/RelatorioDesempenhoProdutoAvaliado"));

const ListagemInscricoesProdutor = async(() => import("./pages/relatorios/InscricoesProdutorList"));
const ListagemProdutoresSemInscricao = async(() => import("./pages/relatorios/ProdutoresSemInscricaoList"));
//Usuarios components
const ListagemUsuarios = async(() => import("./pages/usuarios/List"));
const NovoUsuarios = async(() => import("./pages/usuarios/New"));
const DetalheUsuario = async(() => import("./pages/usuarios/Detail"));
const DetalhePerfilUsuario = async(() => import("./pages/usuarios/perfil/Detail"));

//Produtores components
const ListagemProdutores = async(() => import("./pages/produtor/List"));
const NovoProdutor = async(() => import("./pages/produtor/New"));
const DetalheProdutor = async(() => import("./pages/produtor/Detail"));

//Curadores components
const ListagemCuradores = async(() => import("./pages/curador/List"));
const NovoCurador = async(() => import("./pages/curador/New"));
const DetalheCurador = async(() => import("./pages/curador/Detail"));

//Jurados components
const ListagemJurados = async(() => import("./pages/jurados/List"));
const NovoJurado = async(() => import("./pages/jurados/New"));
const DetalheJurado = async(() => import("./pages/jurados/Detail"));
const CertificadoJuradoImpressao = async(() => import("./pages/jurados/CertificadoJuradoImpressao"));

//Categorias components
const ListagemCategorias = async(() => import("./pages/categorias/List"));
const NovaCategoria = async(() => import("./pages/categorias/New"));
const DetalheCategoria = async(() => import("./pages/categorias/Detail"));

//Fichas Avaliação components
const ListagemFichasAvaliacao = async(() => import("./pages/fichas-avaliacao/List"));
const NovaFichaAvaliacao = async(() => import("./pages/fichas-avaliacao/New"));
const DetalheFichaAvaliacao = async(() => import("./pages/fichas-avaliacao/Detail"));

//Mesas components
const ListagemMesas = async(() => import("./pages/mesas/List"));
const DetalheMesa = async(() => import("./pages/mesas/Detail"));
const RelacaoQueijosMesaImpressao = async(() => import("./pages/mesas/RelacaoQueijosMesaImpressao"));
const RelacaoJuradosMesaImpressao = async(() => import("./pages/mesas/RelacaoJuradosMesaImpressao"));
const QRCodesQueijosMesaImpressao = async(() => import("./pages/mesas/QRCodesQueijosMesaImpressao"));

//Parâmetros concurso components
const ParametrosConcurso = async(() => import("./pages/parametrosConcurso/index"));

//Evento em tempo real components
const EventoTempoReal = async(() => import("./pages/tempoReal/index"));

//Classificação Geral components
const ClassificacaoGeral = async(() => import("./pages/classificacaoGeral/index"));
const CertificadosProdutosPremiadosImpressao = async(() => import("./pages/inscricoes/certificados/sebrae/CertificadosProdutosPremiadosImpressao"));

//Classificação por Categoria components
const ClassificacaoCategoria = async(() => import("./pages/classificacaoCategoria/index"));

//Qualificados para Segunda Fase components
const QualificadosPorFase = async(() => import("./pages/qualificadosPorFase/index"));

//Ficha avaliação concurso components
const FichaAvaliacao = async(() => import("./pages/concurso/fichaAvaliacao"));

let token = "";

if (window.localStorage.getItem("accessToken")) {
  const accessToken = window.localStorage.getItem("accessToken");
  token = jwtDecode(accessToken);
}

const routes = [
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element:
          <Dashboard />
      },
    ]
  },
  {
    path: "login",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "escolha-tipo-cadastro",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <EscolhaTipoCadastro />,
      },
    ],
  },
  {
    path: "cadastro-produtor",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <CadastroProdutor />,
      },
    ],
  },
  {
    path: "cadastro-jurado",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <CadastroJurado />,
      },
    ],
  },
  {
    path: "esqueci-minha-senha",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <EsqueciMinhaSenha />,
      },
    ],
  },
  {
    path: "alteracao-senha",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <AlteracaoSenha />,
      },
    ],
  },
  {
    path: "confirmacao-email",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <ConfirmacaoEmail />,
      },
    ],
  },
  {
    path: "usuarios",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemUsuarios />,
      },
      {
        path: "novo",
        element: <NovoUsuarios />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheUsuario />,
      },
    ],
  },
  {
    path: "perfil",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "detalhe/:id",
        element: <DetalhePerfilUsuario />,
      },
    ],
  },
  {
    path: "produtores",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemProdutores />,
      },
      {
        path: "novo",
        element: <NovoProdutor />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheProdutor />,
      },
    ],
  },
  {
    path: "curadores",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemCuradores />,
      },
      {
        path: "novo",
        element: <NovoCurador />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheCurador />,
      },
    ],
  },
  {
    path: "jurados",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemJurados />,
      },
      {
        path: "novo",
        element: <NovoJurado />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheJurado />,
      },
      {
        path: "certificado/imprimir/:id",
        element: <CertificadoJuradoImpressao />,
      },
    ],
  },
  {
    path: "categorias",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemCategorias />,
      },
      {
        path: "novo",
        element: <NovaCategoria />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheCategoria />,
      },
    ],
  },
  {
    path: "mesas",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemMesas />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheMesa />,
      },
      {
        path: "imprimir-relacao-produtos-mesa/:id",
        element: <RelacaoQueijosMesaImpressao />,
      },
      {
        path: "imprimir-relacao-jurados-mesa/:id",
        element: <RelacaoJuradosMesaImpressao />,
      },
      {
        path: "imprimir-qrcodes-produtos-mesa/:id",
        element: <QRCodesQueijosMesaImpressao />,
      }
    ],
  },
  {
    path: "inscricoes",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemInscricoes />,
      },
      {
        path: "novo",
        element: <NovoInscricao />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheInscricao />,
      },
      {
        path: "imprimir/:id",
        element: <FichaInscricaoImpressao />,
      },
      {
        path: "certificado/imprimir/:ordemFase/:id",
        element: <CertificadoPremiacaoInscricaoImpressao />,
      },
      {
        path: "relatorio/imprimir/:id",
        element: <RelatorioDesempenhoProdutoAvaliado />,
      },
    ],
  },
  {
    path: "inscricoes-produtor",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemInscricoesProdutor />,
      },
    ],
  },
  {
    path: "produtores-sem-inscricoes",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemProdutoresSemInscricao />,
      },
    ],
  },
  {
    path: "parametros-concurso",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ParametrosConcurso />,
      },
    ],
  },
  {
    path: "tempo-real",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <EventoTempoReal />,
      },
    ],
  },
  {
    path: "classificacao-geral",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ClassificacaoGeral />,
      },
      {
        path: "certificados/imprimir/:podio",
        element: <CertificadosProdutosPremiadosImpressao />,
      },
    ],
  },
  {
    path: "classificacao-categoria",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ClassificacaoCategoria />,
      },
    ],
  },
  {
    path: "classificados-por-fase",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <QualificadosPorFase />,
      },
    ],
  },
  {
    path: "avaliacao/:codigo",
    element: (
      <AuthGuard>
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <FichaAvaliacao />,
      },
    ],
  },
  {
    path: "fichas-avaliacao",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemFichasAvaliacao />,
      },
      {
        path: "novo",
        element: <NovaFichaAvaliacao />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheFichaAvaliacao />,
      },
    ],
  },
]

export default routes;