import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Paper as MuiPaper
} from "@mui/material";
import { spacing } from "@mui/system";
import { THEMES } from "../constants";
import masterItems from "../components/sidebar/masterItems";
import administradorItems from "../components/sidebar/administradorItems";
import curadorItems from "../components/sidebar/curadorItems";
import juradoItems from "../components/sidebar/juradoItems";
import produtorItems from "../components/sidebar/produtorItems";
import GlobalStyle from "../components/GlobalStyle";
import "../components/CustomFonts.css";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { getToken } from '../utils/jwt';

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
 ${(props) => props.theme.breakpoints.up("md")} {
    // width: ${drawerWidth}px;
    width: 0px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const HomeLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [token, setToken] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setToken(getToken());
  }, []);

  useEffect(() => {
    if (token) {
      const { role } = token;
      if (role === "Master") {
        setMenuItems(masterItems);
      } else if (role === "Administrador") {
        setMenuItems(administradorItems);
      } else if (role === "Jurado") {
        setMenuItems(juradoItems);
      } else if (role === "Curador") {
        setMenuItems(curadorItems);
      } else {
        setMenuItems(produtorItems);
      }
    }
  }, [token]);


  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={menuItems}
          />
        </Box>
      </Drawer>
      {/* <MuiThemeProvider theme={useTheme(theme)}> */}
      <AppContent className={process.env.REACT_APP_ENV === "production" ? 'production_env' : 'development_env'}>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={12}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
      {/* </MuiThemeProvider> */}
    </Root>
  );
};

export default HomeLayout;