import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";
import {
    Box,
    Grid,
    Button,
    TextField,
    FormHelperText,
    MenuItem,
    CircularProgress,
    FormControlLabel,
    FormControl as MuiFormControl,
    Checkbox,
    Select,
    InputLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import { SearchRounded } from "@mui/icons-material";
import SenhaInput from "../../forms/Senha/SenhaInput";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useNavigate } from "react-router-dom";
// import { obterPaises } from "../../../api/countriesnow";
import { inserirProdutor, verificarDocumentoProdutor } from "../../../api/produtores";
import { inserirUsuario } from "../../../api/usuarios";
import { useTranslation } from "react-i18next";
import { tipoDocBrasilProdutor, servicosSanitarios } from "../../../api/tipoDocumentos";
import { getCEP, validaCNPJ } from "../../../api/auxiliares";
import { CpfValido, CnpjValido } from "../../../utils/validaDocumento";
import ValidacaoDialog from "../../../components/dialogs/ValidacaoDialog";
import ConfirmacaoCadastroProdutorDialog from '../../dialogs/confirmacao-cadastro/ConfirmacaoCadastroProdutorDialog';
import { FormSwitch } from "../../../components/forms/Switch/switch";
import { Helmet } from 'react-helmet-async';

YupPassword(Yup);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const formValues = {
    nomeRepresentante: '',
    nomeFazenda: '',
    // pais: '',
    estado: '',
    cidade: '',
    cep: '',
    tipoDocumento: '',
    nomeDocumento: '',
    numeroDocumento: '',
    possuiServicoSanitario: false,
    servicoSanitario: '',
    numeroServicoSanitario: '',
    numeroSiapp: '',
    celular: '',
    telefone: '',
    email: '',
    // site: '',
    termoAceito: false,
    senha: '',
    // idioma: '',
    termoAceitoCompartilhamentoDados: false,
};

const validationSchema = Yup.object().shape({
    nomeRepresentante: Yup.string().required("Campo obrigatório"),
    nomeFazenda: Yup.string().required("Campo obrigatório"),
    // pais: Yup.string().nullable(),
    cidade: Yup.string(),
    estado: Yup.string(),
    cep: Yup.string(),
    tipoDocumento: Yup.string(),
    numeroDocumento: Yup.string(),
    possuiServicoSanitario: Yup.bool().required("Campo obrigatório"),
    servicoSanitario: Yup.string(),
    numeroServicoSanitario: Yup.string(),
    numeroSiapp: Yup.string(),
    celular: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().nullable(),
    email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
    // site: Yup.string().nullable(),
    termoAceito: Yup.bool().required("Campo obrigatório"),
    senha: Yup.string()
        .required("Campo obrigatória")
        .min(6, 'Senha curta demais - deve conter ao menos 6 caracteres.'),
    // idioma: Yup.string(),
    termoAceitoCompartilhamentoDados: Yup.bool(),
});

function FormularioProdutor() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isFullWidth = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(formValues);
    const [sucesso, setSucesso] = useState(false);
    const [checked, setChecked] = useState(false);
    // const [paises, setPaises] = useState([]);
    // const [paisValue, setPaisValue] = useState('');
    const [tipoDocumentoValue, setTipoDocumentoValue] = useState('');
    const [numeroDocumentoValue, setNumeroDocumentoValue] = useState(null);
    const [cepValue, setCepValue] = useState('');
    const [estadoValue, setEstadoValue] = useState('');
    const [cidadeValue, setCidadeValue] = useState('');
    const [cnpjValido, setCNPJValido] = useState(null);
    const [cpfValido, setCPFValido] = useState(null);
    const [certificadoChecked, setCertificadoChecked] = useState(false);
    const [openConfirmacaoCadastro, setOpenConfirmacaoCadastro] = useState(false);
    const [lgpdChecked, setLgpdChecked] = useState(false);

    //set paises
    // useEffect(() => {
    //   const initialize = async () => {
    //     setLoading(true);
    //     let paisesData = await obterPaises('pt');
    //     setPaises(paisesData);
    //     setLoading(false);
    //   };
    //   initialize();
    // }, []);

    useEffect(() => {
        const initialize = () => {
            if (tipoDocumentoValue === 'CNPJ' && numeroDocumentoValue) {
                //33.177.148/0001-55
                const cnpjFormat = numeroDocumentoValue.replace(/\D/g, "");
                handleCNPJ(cnpjFormat);
            }
            if (tipoDocumentoValue === 'CPF' && numeroDocumentoValue) {
                const cpfFormat = numeroDocumentoValue.replace(/\D/g, "");
                handleCPF(cpfFormat);
            }
        };
        initialize();
    }, [numeroDocumentoValue]);

    const handleCNPJ = _.debounce(async (cnpjFormat) => {
        if (cnpjFormat.length === 14) {
            // const cnpj = await validaCNPJ(cnpjFormat);
            const cnpj = await CnpjValido(cnpjFormat);
            const numeroDoc = await verificarDocumentoProdutor(cnpjFormat);

            // if (!numeroDoc.data.data)
            if (cnpj && !numeroDoc.data.data)
                setCNPJValido(true);
            else
                setCNPJValido(false);
        }
    }, 1000);

    const handleCPF = _.debounce(async (cpfFormat) => {
        if (cpfFormat.length === 11) {
            const cpf = CpfValido(cpfFormat);
            const numeroDoc = await verificarDocumentoProdutor(cpfFormat);

            if (cpf && !numeroDoc.data.data)
                setCPFValido(true);
            else
                setCPFValido(false);
        }
    }, 1000);

    const openAlert = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setError("");
        setOpen(false);
    };

    const handleBuscarCep = () => {
        if (cepValue.length == 9)
            buscarCEP(cepValue);
    };

    const buscarCEP = (value) => {
        const cepFormat = value.replace(/\D/g, "");
        handleCEP(cepFormat);
    };

    const handleCEP = _.debounce(async (cepFormat) => {
        if (cepFormat.length === 8) {
            const endereco = await getCEP(cepFormat);
            setCidadeValue(endereco.data.data.municipio);
            setEstadoValue(endereco.data.data.uf);
        }
    }, 1000);

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            var nome = values.nomeRepresentante.split(' ').slice(0, 1).join(' ');
            var sobrenome = values.nomeRepresentante.split(' ').slice(1).join(' ');

            let usuario = {
                "nome": nome,
                "sobrenome": sobrenome,
                "email": values.email,
                "senha": values.senha,
                "senhaAntiga": null,
                "funcao": "Produtor",
                "idioma": "pt",
            }

            const responseUsuario = await inserirUsuario(usuario);
            const { id } = responseUsuario.data;

            values.usuarioId = id;
            values.pais = 'Brasil';
            values.cep = cepValue;
            values.cidade = cidadeValue;
            values.estado = estadoValue;
            values.tipoDocumento = tipoDocumentoValue;
            values.numeroDocumento = numeroDocumentoValue ? numeroDocumentoValue : values.numeroDocumento;
            values.nomeDocumento = tipoDocumentoValue;
            values.termoAceito = checked;
            values.possuiServicoSanitario = certificadoChecked;
            values.termoAceitoCompartilhamentoDados = lgpdChecked;

            await inserirProdutor(values);
            setSucesso(true);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
            openAlert();
        } catch (error) {
            setStatus({ sent: false });
            setError(error.notificacoes[0].message);
            setSubmitting(false);
            openAlert();
        }
    };

    return (
        <Formik
            setOpen={setOpen}
            open={open}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <React.Fragment>
                    <Helmet title={t('Producer Registration')}>
                        {/* OneTrust */}
                        <script src="https://privacyportal-br-cdn.onetrust.com/consent-receipt-scripts/scripts/otconsent-1.0.min.js"
                            type="text/javascript"
                            data-ot-id="1e33220f-b805-4a8b-973f-6b90e8be838f"
                            charset="UTF-8"
                            id="consent-receipt-script"
                            data-ot-consent-register-event="document-load"
                        ></script>
                    </Helmet>
                    <ValidacaoDialog
                        open={open}
                        error={error}
                        sucesso={sucesso}
                        handleClose={handleClose}
                        message={
                            <span sx={{ fontSize: 32 }}>
                                {t("Successful Registration")}<br />
                                {t("A confirmation e-mail was sent to the registered e-mail address.")} <br />
                                {t("Click on the link in this e-mail to validate your registration.")}
                            </span>
                        }
                        onClick={() => navigate("/login")}
                    />

                    <ConfirmacaoCadastroProdutorDialog
                        open={openConfirmacaoCadastro}
                        onClose={() => { setOpenConfirmacaoCadastro(false); setChecked(false) }}
                        maxWidth={'lg'}
                        setChecked={() => { setOpenConfirmacaoCadastro(false); setChecked(true) }}
                    />

                    {isSubmitting || loading ? (
                        <Box display="flex" justifyContent="center" my={6}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        //mudar o final do data-ot-cp-id para 'draft' em caso de teste
                        <form
                            onSubmit={handleSubmit}
                            class="ot-form-consent"
                            data-ot-cp-id={`bbb44ae5-f7ee-4a63-9559-8469e688aa96-${process.env.REACT_APP_ENV === "development" ? 'draft' : 'active'}`}
                        >
                            <Grid container spacing={4}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nomeRepresentante"
                                        label={`${t("Farm's Legal Representative")}`}
                                        value={values.nomeRepresentante}
                                        error={Boolean(touched.nomeRepresentante && errors.nomeRepresentante)}
                                        fullWidth
                                        helperText={touched.nomeRepresentante && errors.nomeRepresentante}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <FormHelperText>{t("Name of the property responsible.")}</FormHelperText>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nomeFazenda"
                                        label={`${t("Farm's Name")}`}
                                        value={values.nomeFazenda}
                                        error={Boolean(touched.nomeFazenda && errors.nomeFazenda)}
                                        fullWidth
                                        helperText={touched.nomeFazenda && errors.nomeFazenda}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <FormHelperText>{t("This is the name that would be shown in your contest certificate.")}</FormHelperText>
                                </Grid>
                                {/* <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                    id="pais"
                                    name="pais"
                                    value={paisValue}
                                    label={t("Country")}
                                    onBlur={handleBlur}
                                    onChange={value => setPaisValue(value.target.value)}
                                    error={Boolean(touched.pais && errors.pais)}
                                    helperText={touched.pais && errors.pais}
                                    my={2}
                                    select
                                    fullWidth
                                    required
                                    defaultValue=""
                                    >
                                    {paises.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                    </TextField>
                                </Grid> */}
                                <Grid item md={12} sm={12} xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item md={4} sm={8} xs={6}>
                                            <InputMask
                                                mask="99999-999"
                                                value={cepValue}
                                                disabled={false}
                                                maskChar=""
                                                onBlur={handleBlur}
                                                onChange={(event) => setCepValue(event.target.value)}
                                            >
                                                {() => (
                                                    <TextField
                                                        id="cep"
                                                        name="cep"
                                                        label={`${t("CEP")}`}
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={6}>
                                            <Button
                                                startIcon={<SearchRounded />}
                                                variant="contained"
                                                color="primary"
                                                mt={3}
                                                onClick={handleBuscarCep}
                                                className="botao-buscar-cep"
                                            >
                                                {t('Search CEP')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={estadoValue}
                                        name="estado"
                                        label={`${t("State")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={cidadeValue}
                                        name="cidade"
                                        label={`${t("City")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="tipoDocumento">{t("ID Document Type") + '*'}</InputLabel>
                                        <Select
                                            id="tipoDocumento"
                                            name="tipoDocumento"
                                            value={tipoDocumentoValue}
                                            label={t("ID Document Type")}
                                            onChange={(e) => setTipoDocumentoValue(e.target.value)}
                                            required
                                            my={2}
                                        >
                                            {tipoDocBrasilProdutor.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {t(item)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    {(tipoDocumentoValue === 'CPF' || tipoDocumentoValue === 'CNPJ') ?
                                        <InputMask
                                            mask={tipoDocumentoValue === 'CPF' ?
                                                '999.999.999-99'
                                                : tipoDocumentoValue === 'CNPJ' ? "99.999.999/9999-99"
                                                    : null
                                            }
                                            value={numeroDocumentoValue}
                                            onBlur={handleBlur}
                                            onChange={e => setNumeroDocumentoValue(e.target.value)}
                                            disabled={false}
                                            maskChar=""
                                        >
                                            {() => (
                                                <TextField
                                                    name="numeroDocumento"
                                                    label={`${t("Document Number")}`}
                                                    error={(tipoDocumentoValue === 'CNPJ' && !cnpjValido) || (tipoDocumentoValue === 'CPF' && !cpfValido)}
                                                    fullWidth
                                                    helperText={(tipoDocumentoValue === 'CNPJ' && !cnpjValido) ? 'CNPJ inválido ou já cadastrado' : (tipoDocumentoValue === 'CPF' && !cpfValido) && 'CPF inválido ou já cadastrado'}
                                                    variant="outlined"
                                                    required
                                                />
                                            )}
                                        </InputMask> :
                                        <TextField
                                            name="numeroDocumento"
                                            label={`${t("Document Number")}`}
                                            value={values.numeroDocumento}
                                            error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
                                            fullWidth
                                            helperText={touched.numeroDocumento && errors.numeroDocumento}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            required
                                        />
                                    }
                                </Grid>
                                <Grid item md={12}>
                                    <FormSwitch
                                        id="possuiServicoSanitario"
                                        checked={certificadoChecked}
                                        onChange={(event) => setCertificadoChecked(event.target.checked)}
                                        label={t("Health Certificate")}
                                    />
                                </Grid>
                                {certificadoChecked &&
                                    <>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="servicoSanitario">{t("Health Service")}</InputLabel>
                                                <Select
                                                    id="servicoSanitario"
                                                    name="servicoSanitario"
                                                    value={values.servicoSanitario}
                                                    label={t("Health Service")}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    {servicosSanitarios.map((item, index) => (
                                                        <MenuItem key={index} value={item}>
                                                            {t(item)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                value={values.numeroServicoSanitario}
                                                name="numeroServicoSanitario"
                                                label={t("Health Service Number")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="numeroSiapp"
                                        label={t("# SIAPP")}
                                        value={values.numeroSiapp}
                                        error={Boolean(touched.numeroSiapp && errors.numeroSiapp)}
                                        fullWidth
                                        helperText={touched.numeroSiapp && errors.numeroSiapp}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <FormHelperText>
                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeF7tSOjuQ5HTQDZeD_55ssdzJ6SUiFEMdKfKO7wEttUmK68Q/viewform" target="_blank">
                                            {t("# SIAPP available here.")}
                                        </a> Caso não saiba o seu número SIAPP, preencha este campo com a palavra <b>'Sim'</b>.
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="email"
                                        type="email"
                                        label={`${t("E-mail")}`}
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        autoComplete='email'
                                        required
                                        id="Email"
                                        className='ot-form-control'
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={"(99) 99999-9999"}
                                        value={values.celular}
                                        disabled={false}
                                        maskChar=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                name="celular"
                                                label={`${t("Cellphone Number")}`}
                                                value={values.celular}
                                                error={Boolean(touched.celular && errors.celular)}
                                                fullWidth
                                                helperText={touched.celular && errors.celular}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={"(99) 9999-9999"}
                                        value={values.telefone}
                                        disabled={false}
                                        maskChar=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                name="telefone"
                                                label={`${t("Phone Number")}`}
                                                value={values.telefone}
                                                error={Boolean(touched.telefone && errors.telefone)}
                                                fullWidth
                                                helperText={touched.telefone && errors.telefone}
                                                variant="outlined"

                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                {/* <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="site"
                                        label={`${t("Website")}`}
                                        value={values.site}
                                        error={Boolean(touched.site && errors.site)}
                                        fullWidth
                                        helperText={touched.site && errors.site}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </Grid> */}
                                <Grid item md={12}>
                                    <SenhaInput
                                        id="senha"
                                        name="senha"
                                        label={t("Password")}
                                        value={values.senha}
                                        error={Boolean(touched.senha && errors.senha)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText>
                                        {t("The password must have at least 6 characters.")}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={12}>
                                    <div class="ot-checkbox">
                                        <FormSwitch
                                            id="LGPD"
                                            checked={lgpdChecked}
                                            onChange={value => setLgpdChecked(value.target.checked)}
                                            label="Em observância à Lei nº. 13.709/18 - Lei Geral de Proteção de Dados Pessoais e demais normativas aplicáveis sobre proteção de dados pessoais, manifesto-me de forma, livre, informada e consciente ao autorizar o Sistema SEBRAE a realizar o tratamento de meus dados pessoais para as finalidades aqui estabelecidas: Envio de conteúdos, realizar possível contato e e realizar pesquisas do interesse do Sebrae."
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <FormSwitch
                                        id="termoAceito"
                                        checked={checked}
                                        onChange={value => setOpenConfirmacaoCadastro(value.target.checked)}
                                        label={t("I have read and agreed to the Terms and Contest Regulation.")}
                                    />
                                    <br />
                                    <a href="https://image.divulga.sebraemt.com.br/lib/fe3811727364047e701571/m/1/4262cb8c-4268-43fd-a445-02691a1afb5c.pdf" target="_blank" style={{ color: '#000', textDecoration: 'underline' }}>
                                        {t("Click here to read the Terms and Contest Regulation.")}
                                    </a>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth={isFullWidth}
                                        disabled={!checked}
                                        id="Enviar"
                                        className="ot-submit-button"
                                    >
                                        {t("Register")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
}

export default FormularioProdutor;