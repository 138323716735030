import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import {
    Tooltip,
    Menu,
    MenuItem,
    IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { signOut } = useAuth();

    let token = "";

    if (window.localStorage.getItem("accessToken")) {
      const accessToken = window.localStorage.getItem("accessToken");
      token = jwtDecode(accessToken);
    }
  
    let user = { id: token.id };
  
    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = async () => {
        await signOut();
        navigate("/login");
    };

    return (
        <React.Fragment>
            <Tooltip title="Account">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    size="large"
                >
                    <Power />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => navigate(`/perfil/detalhe/${user.id}`)}>{t("Profile")}</MenuItem>
                <MenuItem onClick={handleSignOut}>{t("Logout")}</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default NavbarUserDropdown;