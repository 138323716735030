import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { getToken } from '../../utils/jwt';
import Logotipo from '../../images/logotipos/senai/logo_azul.png';
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PagamentoDialog from "../dialogs/PagamentoDialog";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  // color: #919191;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  // const [token, setToken] = useState(null);
  // const [open, setOpen] = useState(false);
  // const [userRole, setUserRole] = useState('');
  // const [estrangeiro, setEstrangeiro] = useState(false);
  // const [carrinhoLength, setCarrinhoLength] = useState(0);
  // const [invisible, setInvisible] = useState(true);

  // useEffect(() => {
  //   setToken(getToken());
  // }, []);

  // useEffect(() => {
  //   if (token) {
  //     const { role, estrangeiro } = token;
  //     setEstrangeiro(estrangeiro);
  //     setUserRole(role);
  //     initialize();
  //   }
  // }, [token]);

  // const initialize = async () => {
  //   if (userRole === 'Produtor') {
  //     try {
  //       const queijos = JSON.parse(localStorage.getItem('queijos'));

  //       var itensNaoPagos = queijos.filter(queijo => {
  //         return (queijo.pago === false && (queijo.statusPagamento === 'Aberto' || queijo.statusPagamento === 'Vencida'))
  //       })

  //       if (itensNaoPagos.length > 0) {
  //         setInvisible(false);
  //         setCarrinhoLength(itensNaoPagos.length);
  //       }
  //       else {
  //         setInvisible(true);
  //         setCarrinhoLength(0);
  //       }
  //     } catch {
  //       clearInterval(intervalId);
  //     }
  //   }
  // };

  // const checkCarrinho = () => {
  //   initialize();
  // };

  //atualiza o contador do carrinho
  // var intervalId = setInterval(checkCarrinho, 2000);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <React.Fragment>
      {/* <PagamentoDialog 
        open={open}
        onClose={handleClose}
      /> */}

      <AppBar position="sticky" elevation={0} className="app-bar">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <img
                style={{ margin: '8px auto', height: "50px", display: 'block' }}
                src={Logotipo}
              />
            </Grid>
            {/*<Grid item>
               <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search> 
            </Grid>*/}
            <Grid item>
              {/* {(userRole === 'Produtor' && estrangeiro === 'false') &&
                <IconButton
                  color="inherit"
                  aria-label="Carrinho"
                  size="large"
                  onClick={() => navigate("/carrinho")}
                >
                  <Badge badgeContent={carrinhoLength} color="warning" invisible={invisible}>
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              } */}
              {/* <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown /> */}
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
export default withTheme(Navbar);