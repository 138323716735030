import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Stack,
} from "@mui/material";

function ConfirmacaoCadastroProdutorDialog({ open, onClose, maxWidth, setChecked }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isFullWidth = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='alert-dialog'
            maxWidth={maxWidth}
        >
            <div>
                <DialogTitle id="alert-dialog-title" sx={{ marginBottom: 5 }}>
                    {t('Terms of Service and Privacy Policy')}
                </DialogTitle>

                <DialogContent className="inscricao-produto-description" sx={{ maxWidth: '600px' }}>
                        <>
                            <Grid container spacing={4}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Box sx={{ width: '100%', overflow: 'auto', maxHeight: '350px'}}>
                                        <Typography variant="subtitle1" gutterBottom sx={{textAlign: 'justify'}}>
                                        Em observância à Lei nº. 13.709/18 - Lei Geral de Proteção de Dados Pessoais e 
                                        demais normativas aplicáveis sobre proteção de dados pessoais, manifesto-me de 
                                        forma, livre, informada e consciente ao autorizar o Sistema SEBRAE a realizar o 
                                        tratamento de meus dados pessoais para as finalidades aqui estabelecidas: 
                                        analisar as sugestões/reclamações e realizar possível contato, quando necessário; 
                                        realizar a triagem de solicitações por prioridade e realizar pesquisas do interesse 
                                        do Sebrae, bem como o uso de minha imagem e voz, em todo e qualquer material entre 
                                        fotos, documentos outros meios de comunicação, para campanhas promocionais e 
                                        institucionais desta empresa.
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom sx={{textAlign: 'justify'}}>
                                        Ao realizar o cadastramento neste site o participante autoriza o compartilhamento 
                                        dos resultados das análises laboratoriais e dados <b>APENAS</b> com os <b>Representantes do 
                                        Sebrae</b> pertencentes à Comissão Técnica do Concurso, confirma estar de acordo e 
                                        cumpre com todos os termos deste Edital.
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom sx={{textAlign: 'justify'}}>
                                        {t("By continuing, you agree to the Terms of Service and Privacy Policy.")}
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: 'justify'}}>
                                        <a href="https://image.divulga.sebraemt.com.br/lib/fe3811727364047e701571/m/1/4262cb8c-4268-43fd-a445-02691a1afb5c.pdf" target="_blank" style={{ color: '#000', textDecoration: 'underline' }}>
                                            {t("Click here to read the Terms and Contest Regulation.")}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                </DialogContent>
            </div>
            <DialogActions>
                <Grid item container justifyContent="flex-end">
                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 5 }}>
                        <Button variant="outlined" onClick={onClose} fullWidth={isFullWidth}>{t("Cancel")}</Button>
                        <Button variant="contained" onClick={setChecked} fullWidth={isFullWidth}>
                            {t("I've read and agreed to the terms and privacy policy")}
                        </Button>
                    </Stack>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmacaoCadastroProdutorDialog;