import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import AuthLogo from '../components/logotipo/AuthLogo';
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";
import AuthLanguagesDropdown from "../components/auth/AuthLanguagesDropdown";
import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import LogoHzero from '../images/logo_hzero_PB.png';

const Spacer = styled.div(spacing);

const Root = styled.div`
  max-width: 720px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: flex;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
    margin-right: 10px;
  }
`;

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <React.Fragment>
        <AuthLogo />
        <Spacer mb={6} />
        <Helmet title="CMS Sebrae" />
        {children}
      </React.Fragment>
      <Outlet />
      <Settings />
      <AuthLanguagesDropdown />
      <Grid container item xs={12} md={6} justifyContent="flex-end" className='hzero-brand'>
        <List>
          <ListItemButton component={NavLink} to="https://hzero.com.br/">
            <ListItemText
              primary={`© ${new Date().getFullYear()} - Desenvolvido por`}
            />
            <img src={LogoHzero} style={{ height: '18px' }} />
          </ListItemButton>
        </List>
      </Grid>
    </Root >
  );
};

export default Auth;