import React from "react";
import { useTranslation } from "react-i18next";
import {
    Tooltip,
    Menu,
    MenuItem,
    Typography,
    Button,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const languageOptions = {
    pt: {
        name: "Português",
    },
    en: {
        name: "English",
    },
    // es: {
    //     name: "Español",
    // },
}

function AuthLanguagesDropdown() {
    const { t, i18n } = useTranslation();
    const [anchorMenu, setAnchorMenu] = React.useState(null);

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        closeMenu();
    };

    return (
        <React.Fragment>
            <Tooltip title="Languages">
                <Button
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    sx={{
                        border: '1px solid #ccc',
                        background: '#fff',
                        padding: '5px 2px 5px 8px',
                        width: '95px',
                        color: '#999'
                    }}
                >
                    <Typography>
                        {t("Language")}
                        <ArrowDropDownIcon />
                    </Typography>
                </Button>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                {Object.keys(languageOptions).map((language) => (
                    <MenuItem
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                    >
                        {languageOptions[language].name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment >
    );
}

export default AuthLanguagesDropdown;