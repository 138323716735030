import axios from "../utils/axios";

export function listarJurados() {
    return axios.get(`/api/jurados`);
}

export function listarJuradosPaginado(pagina, registroPagina) {
    return axios.get(`/api/jurados/paginado?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

export function listarJuradosAprovadosPorFase(faseId, pagina, registroPagina) {
    return axios.get(`/api/jurados/aprovados/${faseId}?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

// export function listarJuradosAprovadosPorFasePaginado(faseId, pagina, registroPagina) {
//     return axios.get(`/api/jurados/aprovados/${faseId}/paginado?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
// }

export function listarJuradosDisponiveis(faseId, pagina, registroPagina) {
    let url = `/api/jurados/disponiveis`;

    if (faseId)
        url = url + `?faseId=${faseId}`;

    return axios.get(url + `?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

export function listarJuradosDisponiveisMesa(pagina, registroPagina) {
    return axios.get(`/api/jurados/disponiveis/mesas?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

export function obterJurado(id) {
    return axios.get(`/api/jurados/${id}`);
}

export function inserirJurado(data) {
    return axios.post(`/api/jurados`, data)
        .catch(function (error) {
            throw error;
        });
}

export function atualizarJurado(id, data) {
    return axios.put(`/api/jurados/${id}`, data)
        .catch(function (error) {
            throw error;
        });
}

export function excluirJurado(id) {
    return axios.delete(`/api/jurados/${id}`);
}

export function aprovarJurado(id) {
    return axios.post(`/api/jurados/${id}/aprovacoes?aprovado=true`)
        .catch(function (error) {
            throw error;
        });
}

export function reprovarJurado(id) {
    return axios.post(`/api/jurados/${id}/aprovacoes?aprovado=false`);
}

export function aprovarJuradoPorFase(id, faseId) {
    return axios.post(`/api/jurados/${id}/aprovacoes/${faseId}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
}

export function obterJuradoToken() {
    return axios.get(`/api/jurados/token`);
}