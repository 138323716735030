import axios from "../utils/axios";

export function getCEP(cep) {
    return axios.get(`/api/auxiliares/cep/${cep}`)
    .catch(function (error) {
        throw error;
    });
}

export function validaCNPJ(cnpj) {
    return axios.get(`/api/auxiliares/cnpj/${cnpj}`)
    .catch(function (error) {
        throw error;
    });
}