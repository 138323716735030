import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";
import {
    Box,
    Grid,
    Button,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio,
    Chip,
    OutlinedInput,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import SenhaInput from "../../forms/Senha/SenhaInput";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useNavigate } from "react-router-dom";
import { obterPaises } from "../../../api/countriesnow";
import { inserirJurado } from "../../../api/jurados";
import { inserirUsuario } from "../../../api/usuarios";
import { getCEP } from "../../../api/auxiliares";
import { useTranslation } from "react-i18next";
import { tipoDocBrasilJurado, tipoDocInternacionalJurado } from '../../../api/tipoDocumentos';
import ValidacaoDialog from "../../../components/dialogs/ValidacaoDialog";
import {CpfValido} from "../../../utils/validaDocumento";
import { FormSwitch } from "../../../components/forms/Switch/switch";
import { Helmet } from 'react-helmet-async';

YupPassword(Yup);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const idiomasDominioOptions = [
    'Português',
    'Inglês',
    'Espanhol',
    'Italiano',
    'Francês',
    'Outro',
];

const formValues = {
    nome: '',
    email: '',
    celular: '',
    cep: '',
    // pais: '',
    estado: '',
    cidade: '',
    tipoDocumento: '',
    numeroDocumento: '',
    // nacionalidade: '',
    idiomas: [],
    sexo: '',
    formacao: '',
    atividadeProfissional: '',
    ondeTrabalha: '',
    experiencia: '',
    restricao: false,
    descricaoRestricao: '',
    termoAceito: false,
    senha: '',
    termoAceitoCompartilhamentoDados: false,
};

const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
    celular: Yup.string().required("Campo obrigatório"),
    cep: Yup.string(),
    // pais: Yup.string(),
    estado: Yup.string(),
    cidade: Yup.string(),
    tipoDocumento: Yup.string(),
    numeroDocumento: Yup.string(),
    // nacionalidade: Yup.string().required("Campo obrigatório"),
    idiomas: Yup.array().required("Campo obrigatório"),
    sexo: Yup.string().required("Campo obrigatório"),
    formacao: Yup.string().required("Campo obrigatório"),
    atividadeProfissional: Yup.string().required("Campo obrigatório"),
    ondeTrabalha: Yup.string().required("Campo obrigatório"),
    experiencia: Yup.string(),
    outraExperiencia: Yup.string(),
    restricao: Yup.bool().required("Campo obrigatório"),
    descricaoRestricao: Yup.string(),
    termoAceito: Yup.bool().required("Campo obrigatório"),
    senha: Yup.string()
        .required("Campo obrigatório")
        .min(6, 'Senha curta demais - deve conter ao menos 6 caracteres.'),
    termoAceitoCompartilhamentoDados: Yup.bool(),
});

function FormularioJurado() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isFullWidth = useMediaQuery(theme.breakpoints.down("md"));
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(formValues);
    const [sucesso, setSucesso] = useState(false);
    const [checkedTermos, setCheckedTermos] = useState(false);

    const [idiomasDominioValue, setIdiomasDominioValue] = useState([]);
    const [paises, setPaises] = useState([]);
    const [paisValue, setPaisValue] = useState('Brasil');
    const [cepValue, setCepValue] = useState('');
    const [estadoValue, setEstadoValue] = useState('');
    const [cidadeValue, setCidadeValue] = useState('');
    const [tipoDocumentoValue, setTipoDocumentoValue] = useState('');
    const [numeroDocumentoValue, setNumeroDocumentoValue] = useState(null);
    const [cpfValido, setCPFValido] = useState(null);
    const [restricaoValue, setRestricaoValue] = useState(false);
    const [experienciaValue, setExperienciaValue] = useState('');
    const [outraExperienciaValue, setOutraExperienciaValue] = useState('');
    const [lgpdChecked, setLgpdChecked] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            let paisesData = await obterPaises('pt');
            setPaises(paisesData);
        };
        initialize();
    }, []);

    useEffect(() => {
        const initialize = async () => {
            if (tipoDocumentoValue === 'CPF' && numeroDocumentoValue) {
                const cpfFormat = numeroDocumentoValue.replace(/\D/g, "");
                handleCPF(cpfFormat);
            }
        };
        initialize();
    }, [numeroDocumentoValue]);

    const handleCPF = _.debounce((cpfFormat) => {
        if (cpfFormat.length === 11) {
            const cpf = CpfValido(cpfFormat);
            if (cpf)
                setCPFValido(true);
            else
                setCPFValido(false);
        }
    }, 1000);

    const handleClose = () => {
        setError('');
        setOpen(false);
    };

    const openAlert = () => {
        setOpen(true);
    };

    const handleBuscarCep = () => {
        if (cepValue.length == 9)
            buscarCEP(cepValue);
    };

    const buscarCEP = (value) => {
        const cepFormat = value.replace(/\D/g, "");
        handleCEP(cepFormat);
    };

    const handleCEP = _.debounce(async (cepFormat) => {
        if (cepFormat.length === 8) {
            const endereco = await getCEP(cepFormat);
            setCidadeValue(endereco.data.data.municipio);
            setEstadoValue(endereco.data.data.uf);
        }
    }, 1000);

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            var nome = values.nome.split(' ').slice(0, 1).join(' ');
            var sobrenome = values.nome.split(' ').slice(1).join(' ');

            let usuario = {
                "nome": nome,
                "sobrenome": sobrenome,
                "email": values.email,
                "senha": values.senha,
                "senhaAntiga": '',
                "funcao": "Jurado",
                "idioma": "pt",
            }

            const responseUsuario = await inserirUsuario(usuario);
            const { id } = responseUsuario.data;

            values.usuarioId = id;
            values.pais = paisValue;
            values.cep = cepValue;
            values.cidade = cidadeValue;
            values.estado = estadoValue;
            values.nomeDocumento = tipoDocumentoValue;
            values.numeroDocumento = numeroDocumentoValue;
            values.tipoDocumento = tipoDocumentoValue;
            values.idiomas = idiomasDominioValue;
            values.termoAceito = checkedTermos;
            values.restricao = restricaoValue;
            values.aprovado = false;
            values.termoAceitoCompartilhamentoDados = lgpdChecked;
            values.nacionalidade = 'Brasileiro';

            if (experienciaValue === 'Sim')
                values.experiencia = outraExperienciaValue;
            else
                values.experiencia = experienciaValue;

            await inserirJurado(values);
            setSucesso(true);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
            openAlert();

        } catch (error) {
            setStatus({ sent: false });
            setError(error.notificacoes[0].message);
            setSubmitting(false);
            openAlert();
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <React.Fragment>
                    <Helmet title={t('Judge Registration')}>
                        <script
                            src="https://privacyportal-br-cdn.onetrust.com/consent-receipt-scripts/scripts/otconsent-1.0.min.js"
                            type="text/javascript"
                            data-ot-id="1e33220f-b805-4a8b-973f-6b90e8be838f"
                            charset="UTF-8"
                            id="consent-receipt-script"
                            data-ot-consent-register-event="document-load"
                        ></script>
                    </Helmet>
                    <ValidacaoDialog
                        open={open}
                        error={error}
                        sucesso={sucesso}
                        handleClose={handleClose}
                        message={
                            <span sx={{ fontSize: 32 }}>
                                {t("Successful Registration")}<br />
                                {t("A confirmation e-mail was sent to the registered e-mail address.")} <br />
                                {t("Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.")} <br />
                                {t("Click on the link in this e-mail to validate your registration.")}
                            </span>
                        }
                        onClick={() => navigate("/login")}
                    />

                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={6}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        //mudar o final do data-ot-cp-id para 'draft' em caso de teste
                        <form
                            onSubmit={handleSubmit}
                            class="ot-form-consent"
                            data-ot-cp-id={`96077898-5d93-4601-8475-23e3b4965c9d-${process.env.REACT_APP_ENV === "development" ? 'draft' : 'active'}`}
                        >
                            <Grid container spacing={4}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        name="nome"
                                        label={t("Full Name")}
                                        value={values.nome}
                                        error={Boolean(touched.nome && errors.nome)}
                                        fullWidth
                                        helperText={touched.nome && errors.nome}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        name="email"
                                        type="email"
                                        label={`${t("E-mail")}`}
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        autoComplete='email'
                                        required
                                        id="Email"
                                        className='ot-form-control'
                                    />
                                </Grid>
                                {/* <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        id="pais"
                                        name="pais"
                                        value={paisValue}
                                        label={t("Country")}
                                        onBlur={handleBlur}
                                        onChange={value => setPaisValue(value.target.value)}
                                        error={Boolean(touched.pais && errors.pais)}
                                        helperText={touched.pais && errors.pais}
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                        defaultValue=""
                                    >
                                        {paises.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> */}
                                {/* {paisValue === 'Brasil' && */}
                                <Grid item md={12} sm={12} xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item md={4} sm={8} xs={6}>
                                            <InputMask
                                                mask="99999-999"
                                                value={cepValue}
                                                disabled={false}
                                                maskChar=""
                                                onBlur={handleBlur}
                                                onChange={(event) => setCepValue(event.target.value)}
                                            >
                                                {() => (
                                                    <TextField
                                                        id="cep"
                                                        name="cep"
                                                        label={`${t("CEP")}`}
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={6}>
                                            <Button
                                                startIcon={<SearchRounded />}
                                                variant="contained"
                                                color="primary"
                                                mt={3}
                                                onClick={handleBuscarCep}
                                                className="botao-buscar-cep"
                                            >
                                                {t('Search CEP')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* } */}
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={estadoValue}
                                        name="estado"
                                        label={`${t("State")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={cidadeValue}
                                        name="cidade"
                                        label={`${t("City")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="tipoDocumento">{t("ID Document Type") + '*'}</InputLabel>
                                        <Select
                                            id="tipoDocumento"
                                            name="tipoDocumento"
                                            value={tipoDocumentoValue}
                                            label={t("ID Document Type")}
                                            onChange={(e) => setTipoDocumentoValue(e.target.value)}
                                            required
                                            my={2}
                                        >
                                            {paisValue === 'Brasil' ?
                                                tipoDocBrasilJurado.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                )) :
                                                tipoDocInternacionalJurado.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={tipoDocumentoValue === 'CPF' ?
                                            '999.999.999-99'
                                            : tipoDocumentoValue === 'RG' ? "99.999.999-9"
                                                : null
                                        }
                                        value={numeroDocumentoValue}
                                        onBlur={handleBlur}
                                        onChange={e => setNumeroDocumentoValue(e.target.value)}
                                        disabled={false}
                                        maskChar=""
                                    >
                                        {() => (
                                            <TextField
                                                name="numeroDocumento"
                                                label={`${t("Document Number")}`}
                                                error={(tipoDocumentoValue === 'CPF' && !cpfValido)}
                                                fullWidth
                                                helperText={(tipoDocumentoValue === 'CPF' && !cpfValido) && 'CPF inválido'}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={paisValue === 'Brasil' ? "(99) 99999-9999" : '(+99) 999-999-9999'}
                                        value={values.celular}
                                        disabled={false}
                                        maskChar=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                name="celular"
                                                label={t("Cellphone Number")}
                                                error={Boolean(touched.celular && errors.celular)}
                                                fullWidth
                                                required
                                                helperText={touched.celular && errors.celular}
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item md={12}>
                                    <FormControl sx={{ marginTop: 2, marginBottom: 2 }} fullWidth>
                                        <InputLabel id="idiomas-label">{t("Proficiency Languages")}</InputLabel>
                                        <Select
                                            labelId="idiomas-label"
                                            id="idiomas"
                                            name="idiomas"
                                            multiple
                                            value={idiomasDominioValue}
                                            onChange={
                                                (event) => {
                                                    const { target: { value } } = event;
                                                    setIdiomasDominioValue(
                                                        // On autofill we get a stringified value.
                                                        typeof value === 'string' ? value.split(',') : value,
                                                    );
                                                }}
                                            input={<OutlinedInput id="select-multiple-languages" label="Idiomas de Domínio" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {idiomasDominioOptions.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{t("List every language you have proficiency.")}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="sexo"
                                        value={values.sexo}
                                        label={t("Gender")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.sexo && errors.sexo
                                        )}
                                        helperText={
                                            touched.sexo && errors.sexo
                                        }
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="Masculino">{t("Male")}</MenuItem>
                                        <MenuItem value="Feminino">{t("Female")}</MenuItem>
                                        <MenuItem value="Outro">{t("Other")}</MenuItem>
                                    </TextField>
                                </Grid>
                                {/* <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nacionalidade"
                                        label={t("Nationality")}
                                        value={values.nacionalidade}
                                        error={Boolean(touched.nacionalidade && errors.nacionalidade)}
                                        fullWidth
                                        helperText={touched.nacionalidade && errors.nacionalidade}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid> */}
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="formacao"
                                        label={t("Educational Background")}
                                        value={values.formacao}
                                        error={Boolean(touched.formacao && errors.formacao)}
                                        fullWidth
                                        helperText={touched.formacao && errors.formacao}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="atividadeProfissional"
                                        value={values.atividadeProfissional}
                                        label={t("Professional Activity")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.atividadeProfissional && errors.atividadeProfissional
                                        )}
                                        helperText={
                                            touched.atividadeProfissional && errors.atividadeProfissional
                                        }
                                        my={2}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="ondeTrabalha"
                                        value={values.ondeTrabalha}
                                        label={t("Company")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.ondeTrabalha && errors.ondeTrabalha
                                        )}
                                        helperText={
                                            touched.ondeTrabalha && errors.ondeTrabalha
                                        }
                                        my={2}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                {/* <Grid item md={6} sm={12} xs={12} /> */}
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="experiencia"
                                        value={experienciaValue}
                                        label={t("Product sensory analysis experience")}
                                        onBlur={handleBlur}
                                        onChange={e => setExperienciaValue(e.target.value)}
                                        error={Boolean(touched.experiencia && errors.experiencia)}
                                        helperText={touched.experiencia && errors.experiencia}
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="Sim">{t("Yes")}</MenuItem>
                                        <MenuItem value="Não">{t("No")}</MenuItem>
                                    </TextField>
                                </Grid>
                                {experienciaValue === 'Sim' &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            name="outraExperiencia"
                                            value={outraExperienciaValue}
                                            label={t("Experience details:")}
                                            onBlur={handleBlur}
                                            onChange={e => setOutraExperienciaValue(e.target.value)}
                                            error={Boolean(
                                                touched.outraExperiencia && errors.outraExperiencia
                                            )}
                                            helperText={
                                                touched.outraExperiencia && errors.outraExperiencia
                                            }
                                            my={2}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                }
                                 <Grid item md={12}>
                                    <FormSwitch
                                        id="termoAceito"
                                        checked={restricaoValue}
                                        onChange={value => setRestricaoValue(value.target.checked)}
                                        label={t("Are there any restrictions on judging a certain type of cheese?")}
                                    />
                                </Grid>
                                {restricaoValue &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            name="descricaoRestricao"
                                            label={t("Describe your dietary restriction")}
                                            value={values.descricaoRestricao}
                                            error={Boolean(touched.descricaoRestricao && errors.descricaoRestricao)}
                                            fullWidth
                                            helperText={touched.descricaoRestricao && errors.descricaoRestricao}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item md={12}>
                                    <SenhaInput
                                        id="senha"
                                        name="senha"
                                        label={t("Password")}
                                        value={values.senha}
                                        error={Boolean(touched.senha && errors.senha)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText>
                                        {t("The password must have at least 6 characters.")}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={12}>
                                    <div class="ot-checkbox">
                                        <FormSwitch
                                            id="LGPD"
                                            checked={lgpdChecked}
                                            onChange={value => setLgpdChecked(value.target.checked)}
                                            label="Em observância à Lei nº. 13.709/18 - Lei Geral de Proteção de Dados Pessoais e demais normativas aplicáveis sobre proteção de dados pessoais, manifesto-me de forma, livre, informada e consciente ao autorizar o Sistema SEBRAE a realizar o tratamento de meus dados pessoais para as finalidades aqui estabelecidas: Envio de conteúdos, realizar possível contato e e realizar pesquisas do interesse do Sebrae."
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <FormSwitch
                                        id="termoAceito"
                                        checked={checkedTermos}
                                        onChange={value => setCheckedTermos(value.target.checked)}
                                        label={t("I have read and agreed to the Terms and Contest Regulation.")}
                                    />
                                    <br />
                                    <a href="https://image.divulga.sebraemt.com.br/lib/fe3811727364047e701571/m/1/4262cb8c-4268-43fd-a445-02691a1afb5c.pdf" target="_blank" style={{ color: '#000', textDecoration: 'underline' }}>
                                        {t("Click here to read the Terms and Contest Regulation.")}
                                    </a>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth={isFullWidth}
                                        disabled={!checkedTermos}
                                        id="Enviar"
                                        className="ot-submit-button"
                                    >
                                        {t("Register")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
}

export default FormularioJurado;