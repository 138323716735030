import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import jwtDecode from "jwt-decode";

const resources = {
  en: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welcome back",
      "Yes": "Yes",
      "No": "No",
      "Dashboard": "Dashboard",
      "We've missed you": "We've missed you",
      "Users": "Users",
      "User": "User",
      "user": "user",
      "judge": "judge",
      "producer": "producer",
      "Edit User": "Edit User",
      "Edit Producer": "Edit Producer",
      "Edit Judge": "Edit Judge",
      "Add User": "Add User",
      "Add Producer": "Add Producer",
      "Add Judge": "Add Judge",
      "Name": "Name",
      "Full Name": "Full Name",
      "Lastname": "Lastname",
      "E-mail": "E-mail",
      "Language": "Language",
      "Languages": "Languages",
      "Role": "Role",
      "Actions": "Actions",
      "New User": "New User",
      "Save": "Save",
      "Save Changes": "Save Changes",
      "Producer": "Producer",
      "Judge": "Judge",
      "Produtor": "Producer",
      "Jurado": "Judge",
      "Judges": "Judges",
      "Admin": "Admin",
      "Administrador": "Admin",
      "Password": "Password",
      "Confirm Password": "Confirm Password",
      "The password must have at least 6 characters.": "The password must have at least 6 characters.",
      "Portuguese": "Portuguese",
      "English": "English",
      "Spanish": "Spanish",
      "Italian": "Italian",
      "French": "French",
      "Close": "Close",
      "Occured an Error": "Error",
      "Done": "Done",
      "User registred successfully": "User registred successfully",
      "Required field": "Required field",
      "User updated successfully": "User updated successfully",
      "Product registered successfully": "Product registered successfully",
      "Registration updated successfully": "Registration updated successfully",
      "Farm's Name": "Farm's Name",
      "Farm's Legal Representative": "Farm's Legal Representative",
      "Country": "Country",
      "State": "State",
      "Status": "Status",
      "Active": "Active",
      "Inactive": "Inactive",
      "Producers": "Producers",
      "Judges": "Judges",
      "User agreed with the terms and Contest Regulation.": "User agreed with the terms and Contest Regulation.",
      "I have read and agreed to the Terms and Contest Regulation.": "I have read and agreed to the Terms and Contest Regulation.",
      "Every term and contest regulation have been agreed by the user.": "Every term and contest regulation have been agreed by the user.",
      "Website": "Website",
      "Phone Number": "Phone Number",
      "Cellphone Number": "Cellphone Number",
      "Document Number": "Document Number",
      "RG": "RG",
      "CPF": "CPF",
      "CNPJ": "CNPJ",
      "Passport": "Passport",
      "ID Document Type": "ID Document Type",
      "City": "City",
      "Zip-Code": "Zip-Code",
      "This is the name that would be shown in your contest certificate.": "This is the name that would be shown in your contest certificate.",
      "Name of the property responsible.": "Name of the property responsible.",
      "Producer updated successfully": "Producer updated successfully",
      "Judge updated successfully": "Judge updated successfully",
      "Curator updated successfully": "Curator updated successfully",
      "Curator registered successfully": "Curator registered successfully",
      "Producer registered successfully": "Producer registered successfully",
      "Judge registered successfully": "Judge registered successfully",
      "A confirmation e-mail was sent to the registered e-mail address.": "A confirmation e-mail was sent to the registered e-mail address.",
      "This producer needs to be activated by the user.": "This producer needs to be activated by the user.",
      "This judge needs to be activated by the user.": "This judge needs to be activated by the user.",
      "Producer Registration": "Producer Registration",
      "Judge Registration": "Judge Registration",
      "New Producer": "New Producer",
      "New Judge": "New Judge",
      "Subscribe another product": "Subscribe another product",
      "Register new product": "Register new product",
      "Edit Registration": "Edit Registration",
      "Go to payment": "Go to payment",
      "Product's Name": "Product's Name",
      "Certificate Type": "Certificate Type",
      "Municipal Certificate": "Municipal Certificate",
      "State Certificate": "State Certificate",
      "Federal Certificate": "Federal Certificate",
      "Municipal": "Municipal Certificate",
      "Estadual": "State Certificate",
      "Federal": "Federal Certificate",
      "Other": "Other",
      "Selo Arte": "Art Stamp",
      "Art Stamp": "Art Stamp",
      "Certificate: Attach the products's certificate": "Certificate: Attach the products's certificate",
      "Products's Characteristics": "Products's Characteristics",
      "Category": "Category",
      "Origin": "Origin",
      "Milk Type": "Milk Type",
      "Raw": "Raw",
      "Pasteurized": "Pasteurized",
      "Manufacturing Date": "Manufacturing Date",
      "Dough Treatment": "Dough Treatment",
      "Rind Type": "Rind Type",
      "Registrations": "Registrations",
      "Registration": "Registration",
      "New Product Registration": "New Product Registration",
      "Certificate": "Certificate",
      "Code": "Code",
      "Paid": "Paid",
      "Registered Products": "Registered Products",
      "Products": "Products",
      "Orders": "Orders",
      "Orders History": "Orders History",
      "Reviews": "Reviews",
      "Administrative": "Administrative",
      "Brazil": "Brazil",
      "Italy": "Italy",
      "United States": "United States",
      "France": "France",
      "Germany": "Germany",
      "Spain": "Spain",
      "Approved": "Approved",
      "Disapproved": "Disapproved",
      "Pending": "Pending",
      "Approval": "Approval",
      "Occupation": "Occupation",
      "Rows per page:": "Rows per page:",
      "of": "of",
      "more than": "more than",
      "Proficiency Languages": "Proficiency Languages",
      "List every language you have proficiency.": "List every language you have proficiency.",
      "Male": "Male",
      "Female": "Female",
      "Other": "Other",
      "Nationality": "Nationality",
      "Gender": "Gender",
      "Educational Background": "Educational Background",
      "Professional Activity": "Professional Activity",
      "Company": "Company",
      "Product sensory analysis experience": "Product sensory analysis experience",
      "I've never been a cheese contest judge": "I've never been a cheese contest judge",
      "Are there any restrictions on judging a certain type of cheese?": "Are there any restrictions on judging a certain type of cheese?",
      "Describe your dietary restriction": "Describe your dietary restriction",
      "Cart": "Cart",
      "Add File": "Add File",
      "Delete": "Delete",
      "Are you sure that you want to delete": "Are you sure that you want to delete",
      "deleted successfully!": "deleted successfully!",
      "Item deleted successfully!": "Item deleted successfully!",
      "Cart": "Cart",
      "Description": "Description",
      "Amount": "Amount",
      "Go to payment": "Go to payment",
      "Items": "Items",
      "Remove": "Remove",
      "Order Summary": "Order Summary",
      "Contest Parameters": "Contest Parameters",
      "Parameters updated successfully!": "Parameters updated successfully!",
      "Contest Registration End Date": "Contest Registration End Date",
      "Contest Subscripition Start Date": "Contest Subscripition Start Date",
      "Payment Expiring Date": "Payment Expiring Date",
      "Product Registration Cost (R$)": "Product Registration Cost (R$)",
      "Contest Registration Cost (R$)": "Contest Registration Cost (R$)",
      "Logout": "Logout",
      "ID da Propriedade": "Property ID",
      "Passaporte": "Passport",
      "Approve": "Approve",
      "Disapprove": "Disapprove",
      "Success!": "Success!",
      "Warning!": "Warning!",
      "Invoices": "Invoices",
      "You still didn't completed your registration in the Sebrae's 2024 Product Awards!": "You still didn't completed your registration in the Sebrae's 2024 Product Awards!",
      "Finish you registration by clicking in the button below and make the payment of your registration.": "Finish you registration by clicking in the button below and make the payment of your registration.",
      "Order number": "Order number",
      "Order total": "Order total",
      "Thank you for your registration.": "Thank you for your registration.",
      "Cash Credit Card": "Cash Credit Card",
      "Credit card in": "Credit card in",
      "installments": "installments",
      "Pagamento realizado com sucesso!": "Successful payment!",
      "Credit Card": "Credit Card",
      "Payment method": "Payment method",
      "YOUR NAME": "YOUR NAME",
      "Card Number": "Card Number",
      "Card Name": "Card Name",
      "Valid Thru": "Valid Thru",
      "Installments": "Installments",
      "Finish Order": "Finish Order",
      "This is a safe environment, payment made in encrypted environment.": "This is a safe environment, payment made in encrypted environment.",
      "Finish Payment": "Finish Payment",
      "Válido até às": "Valid thru",
      "Brasilia time.": "Brasilia time.",
      "Copy Pix Code": "Copy Pix Code",
      "Payment Info": "Payment Info",
      "Pix à vista": "Pix",
      "Boleto à vista": "Boleto",
      "Order": "Order",
      "Payment Status": "Payment Status",
      "Payment Date": "Payment Date",
      "Value": "Value",
      "Pago": "Paid",
      "Vencida": "Expired",
      "Aguardando pagamento": "Waiting for payment",
      "Expiring Date": "Expiring Date",
      "Expired in": "Expired in",
      "Print": "Print",
      "Payment No.": "Payment No.",
      "Hello,": "Hello",
      "Este é o recibo do pagamento de": "This is the receipt for a payment of",
      "feito para Sebrae Brasil.": "made to Sebrae Brasil.",
      "to Sebrae Brasil.": "to Sebrae Brasil",
      "Este é o recibo vencido de um pedido de": "This is an expired receipt of an order of",
      "Este é o recibo de um pagamento pendente no valor de": "This is a pending payment receipt for an order of",
      "Order Completed": "Order Completed",
      "Inscricao": "Awards Registration",
      "Produto": "Product",
      "Log In": "Log In",
      "Forgot your password?": "Forgot your password?",
      "Sign up": "Sign up",
      "Remember me": "Remember me",
      "Back": "Back",
      "Change my password": "Change my password",
      "Type your e-mail address to receive the instructions to setup your new password.": "Type your e-mail address to receive the instructions to setup your new password.",
      "Back to login": "Back to login",
      "We just sent you the e-mail with the instructions to change your password.": "We just sent you the e-mail with the instructions to change your password.",
      "Did not received the email?": "Did not received the email?",
      "Check your trash or spam inboxes": "Check your trash or spam inboxes",
      "Check you typed correctly your registred e-mail address": "Check you typed correctly your registred e-mail address",
      "Make sure that the e-mail address is registred in our database": "Make sure that the e-mail address is registred in our database",
      "If you still have truble to access your account, contact our support.": "If you still have truble to access your account, contact our support.",
      "Choose your role": "Choose your role",
      "I'm a...": "I'm a...",
      "Paying Method": "Paying Method",
      "CartaoCredito": "Credit Card",
      "Order Details": "Order Details",
      "Click on the link in this e-mail to validate your registration.": "Click on the link in this e-mail to validate your registration.",
      "Return to the login page": "Return to the login page",
      "Experience details:": "Experience details:",
      "Register": "Register",
      "Successful Registration": "Successful Registration",
      "E-mail inválido": "Invalid e-mail",
      "Campo obrigatório": "Mandatory field",
      "Type your new password below.": "Type your new password below.",
      "New Password": "New Password",
      "Password changed successfully": "Password changed successfully",
      "Change password": "Change password",
      "You must attach the certificate.": "You must attach the certificate.",
      "Cannot read properties of undefined (reading 'url')": "You must attach the certificate.",
      "Do you really want to delete the file": "Do you really want to delete the file",
      "Delete file": "Delete file",
      "The certificate is required": "The certificate is required",
      "days": "days",
      "Product Information": "Product Information",
      "Review Sheet": "Review Sheet",
      "Review Sheets": "Review Sheets",
      "Maturation Days": "Maturation Days",
      "Product's Code": "Product's Code",
      "Estragado": "Expired",
      "Péssimo": "Terrible",
      "Ruim": "Bad",
      "Insuficiente": "Not enough",
      "Razoável": "Ordinary",
      "Suficiente": "Enough",
      "Bom": "Good",
      "Ótimo": "Great",
      "Excelente": "Excellent",
      "Perfeito": "Perfect",
      "Send Review": "Send Review",
      "General Aspect": "General Aspect",
      "Color": "Color",
      "Texture": "Texture",
      "Odor": "Odor",
      "Scent": "Scent",
      "Consistency": "Consistency",
      "Flavor": "Flavor",
      "Payment": "Payment",
      "Title / Aspect": "Title / Aspect",
      "Title": "Title",
      "Aspect": "Aspect",
      "Max Rating": "Max Rating",
      "Weight": "Weight",
      "Add New Evaluation Field": "Add New Evaluation Field",
      "Click here to read the Terms and Contest Regulation.": "Click here to read the Terms and Contest Regulation.",
      "Products": "Products",
      "Product": "Product",
      "Registration": "Registration",
      "Cancel": "Cancel",
      "Add": "Add",
      "The user read and agreed with the contest terms and regulation.": "The user read and agreed with the contest terms and regulation.",
      "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.": "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.",
      "User not found.": "User not found.",
      "Usuário ou senha inválidos!": "Invalid user or password!",
      "Queijo Maturado": "Aged",
      "Queijo de Untar, Espalhável (Ex. de passar no pão)": "Greasing Product",
      "Ricotta": "Ricotta",
      "Queijo Coalho": "Curd Product",
      "Queijo de Manteiga": "Butter Product",
      "Queijo Pastoso": "Pasty Product",
      "Queijo de Massa Fundida Requeijão em Barra": "Cottage Product",
      "Queijo de Massa Fundida Requeijão Moreno": "Moreno Cottage Product",
      "Requeijão em Barra": "Cottage Product",
      "Requeijão Moreno": "Moreno Cottage Product",
      "Queijo com Mofo Azul": "Blue Mold Product",
      "Queijo Aromatizado e/ou Condimentado": "Flavored and/or Spiced Product",
      "Fresco com tratamento (mofo ou condimento)": "Processed fresh cheese (mold and/or condiment)",
      "Fresco sem tratamento": "Unprocessed Fresh Product",
      "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)": "Smooth or Washed Rind",
      "Casca Florida (casca com predomínio de fungos filamentosos)": "Flowered Rind",
      "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)": "Treated Rind",
      "Com Aquecimento": "Heated",
      "Sem Aquecimento": "No heated",
      "Queijo Massa Filada": "Spliced Pasta",
      "Queijo Massa Não Filada": "Unspliced Pasta",
      "Cru": "Raw",
      "Pausterizado": "Pausterized",
      "Vaca": "Cow",
      "Cabra": "Goat",
      "Ovelha": "Sheep",
      "Búfala": "Buffalo",
      "Mistura de Diferentes Espécies": "Different Species Mix",
      "Outro": "Other",
      "Attention!": "Attention!",
      "Event": "Event",
      "Awards Info": "Awards Info",
      "Email não confirmado!": "E-mail not enabled!",
      "Resend account validation e-mail": "Resend account validation e-mail",
      "There are no items in your cart.": "There are no items in your cart.",
      "Contest Phase": "Contest Phase",
      "Contest Phases": "Contest Phases",
      "Max Grade": "Max Grade",
      "Add question": "Add question",
      "Contest Start Date": "Contest Start Date",
      "Contest": "Contest",
      "Manufacturing Date cannot be after the event initial date.": "Manufacturing Date cannot be after the event initial date.",
      "Payment Status": "Payment Status",
      "Processando pagamento": "Processing payment",
      "Não pago": "Not paid",
      "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.": "The payment failed: Invalid CPF or CNPJ.",
      "cheese": "cheese",
      "Add phase": "Add phase",
      "Contest Phase Name": "Contest Phase Name",
      "Contest Phase Order": "Contest Phase Order",
      "Contest Tables": "Contest Tables",
      "Shuffle Tables": "Shuffle Tables",
      "Contest Table ID Code": "Contest Table ID Code",
      "Table Details": "Table Details",
      "Registrations by Producers": "Registrations by Producers",
      "Your Reviews": "Your Reviews",
      "New Curator": "New Curator",
      "Curator": "Curator",
      "Curators": "Curators",
      "Edit Curator": "Edit Curator",
      "Makers With No Registrations": "Makers With No Registrations",
      "Phone": "Phone",
      "Pending Payment": "Pending Payment",
      "Reports": "Reports",
      "Table": "Table",
      "Do you want to move": "Do you want to move",
      "to which table?": "to which table?",
      "Move judge to another table": "Move judge to another table",
      "Free": "Free",
      "Move": "Move",
      "Judge moved successfully": "Judge moved successfully",
      "Product moved successfully": "Product moved successfully",
      "Product reviewed successfully": "Product reviewed successfully",
      "Jurado sem acesso ao queijo!": "You are not allowed to review this cheese.",
      "Queijo já avaliado!": "Product already reviewed.",
      "Return": "Return",
      "Return to the previous page": "Return to the previous page",
      "Search": "Search",
      "Filter": "Filter",
      "Table QR Codes": "Table QR Codes",
      "Print Judge's List": "Print Judge's List",
      "Print Product's List": "Print Product's List",
      "Print QR Codes": "Print QR Codes",
      "Untie Priority": "Untie Priority",
      "Real Time": "Real Time",
      "Event in Real Time": "Event in Real Time",
      "Start Phase": "Start Phase",
      "End Phase": "End Phase",
      "Average Score": "Average Score",
      "Qtd. Reviewed": "Qtd. Reviewed",
      "Total Reviews": "Total Reviews",
      "Total Products": "Total Products",
      "Total Judges": "Total Judges",
      "Fase iniciada com sucesso!": "Contest Phase Started Successfully!",
      "Fase finalizada com sucesso!": "Contest Phase Finished Successfully!",
      "Phase already finished.": "Phase already finished.",
      "Reset Phase": "Reset Phase",
      "Phase": "Phase",
      "Your Score (%)": "Your Score (%)",
      'Average Score (%)': 'Average Score (%)',
      'Winner (%)': 'Winner (%)',
      'Category Winner (%)': 'Category Winner (%)',
      "Category Average Score (%)": "Category Average Score (%)",
      "Overall Average Score (%)": "Overall Average Score (%)",
      "Awards Results": "Awards Results",
      "Overall Result": "Overall Result",
      "Category Result": "Category Result",
      "Score": "Score",
      "Awarded": "Awarded",
      "Qualified to Next Phase": "Qualified to Next Phase",
      "Awards Results by Category": "Awards Results by Category",
      "Export": "Export",
      "Classificados da 1ª fase": "Qualified to Second Phase",
      "Classificados da 2ª fase": "Qualified to Third Phase",
      "Qualified to Second Phase": "Qualified to Second Phase",
      "Qualified to Third Phase": "Qualified to Third Phase",
      "Qualified by Phase": "Qualified by Phase",
      "General": "General",
      "Second Phase": "Second Phase",
      "Approve to Second Phase": "Approve to Second Phase",
      "Judge approved to this contest phase!": "Judge approved to this contest phase!",
      "Products Qualified by Phase": "Products Qualified by Phase",
      "Tente novamente mais tarde.": "Try again later.",
      "Refresh": "Refresh",
      "Number of Products by Table": "Number of Products by Table",
      "Number of Judges by Table": "Number of Judges by Table",
      "Selected": "Selected",
      "Not selected": "Not selected",
      "Deselect": "Deselect",
      "Jurado aprovado na fase": "Judge approved to this phase.",
      "Jurado reprovado na fase": "Judge repproved to this phase.",
      "Quantitative Assessment Form": "Quantitative Assessment Form",
      "Ratings from 0 to ": "Ratings from 0 to ",
      "Rating from 0 to ": "Rating from 0 to ",
      "Selecionados": "Selected",
      "Select Judge": "Select Judge",
      "Add Judges - Phase": "Add Judges - Phase",
      "Export Grades": "Export Grades",
      "Reset Event": "Reset Event",
      "Reopen Phase": "Reopen Phase",
      "Are you sure that you want to reopen this phase?": "Are you sure that you want to reopen this phase?",
      "When you reopen a phase, you allow judges to submit new reviews or overwrite their past reviews.": "When you reopen a phase, you allow judges to submit new reviews or overwrite their past reviews.",
      "Select a Category": "Select a Category",
      'Please wait, exporting data...': 'Please wait, exporting data...',
      "Are you sure that you want to re-order the contest tables?": "Are you sure that you want to re-order the contest tables?",
      "When you re-oder the tables, you change all cheeses and judges from its previous tables.": "When you re-oder the tables, you change all cheeses and judges from its previous tables.",
      "All the previous contest setup will be lost and a new one will be made.": "All the previous contest setup will be lost and a new one will be made.",
      'Counting votes': 'Counting votes',
      "Heating": 'Heating',
      "Heated": "Heated",
      "No heated": "No heated",
      "Comment": "Comment",
      "Table Leader": "Table Leader",
      "Set Table Leader": "Set Table Leader",
      "Table Leader Setup": "Table Leader Setup",
      "Move Judge": "Move Judge",
      "Details": "Details",
      "Are you sure that you want to change this table's leader to ": "Are you sure that you want to change this table's leader to ",
      "Warning": "Warning",
      "Table Leader changed successfully!": "Table Leader changed successfully!",
      "Change Table Leader": "Change Table Leader",
      "Scan QR Code": "Scan QR Code",
      "Scan Another QR Code": "Scan Another QR Code",
      "Loading": "Loading",
      "Product Status": "Product Status",
      "Shipped": "Shipped",
      "Edit": "Edit",
      "Unknown": "Unknown",
      "Receive Product": "Receive Product",
      "Validate Product": "Validate Product",
      "Separate Product": "Separate Product",
      "Product received successfully!": "Product received successfully!",
      "Product validated successfully!": "Product validated successfully!",
      "Product separated successfully!": "Product separated successfully!",
      "Able": "Able",
      "Unable": "Unable",
      "Print Certificate": "Print Certificate",
      "Download Certificate": "Download Certificate",
      "Contest Started": "Contest Started",
      "Table Number": "Table Number",
      "Current Contest Phase": "Current Contest Phase",
      "Contest not started yet.": "Contest not started yet.",
      "Table's Leader Veredic": "Table's Leader Veredic",
      "Grade": "Grade",
      "Print Registration Form": "Print Registration Form",
      "Page Not Found": "Page Not Found",
      "The page you are looking for might have been removed.": "The page you are looking for might have been removed.",
      "Return to the Home Page": "Return to the Home Page",
      "Product Award Certificate Release Date": "Product Award Certificate Release Date",
      "Print Review Report": "Print Review Report",
      "Reviews by Attributes": "Reviews by Attributes",
      "Export Product Receipt Report": "Export Product Receipt Report",
      "Add Product to the Table": "Add Product to the Table",
      "Add Judge to the Table": "Add Judge to the Table",
      "Move Product": "Move Product",
      "Move product to another table": "Move product to another table",
      "Add Judge to the Table": "Add Judge to the Table",
      "Profession": "Profession",
      "Registered Products": "Registered Products",
      "New Registration": "New Registration",
      "Awarded Product": "Awarded Product",
      "Producer's Name": "Producer's Name",
      "Product Registration": "Product Registration",
      "User Profile Data": "User Profile Data",
      "Contest Categories": "Contest Categories",
      "Product Categories": "Product Categories",
      "Add Category": "Add Category",
      "Categories": "Categories",
      "Category": "Category",
      "Category added successfully.": "Category added successfully.",
      "Category Registration": "Category Registration",
      "New Category": "New Category",
      "Category updated successfully.": "Category updated successfully.",
      "Edit Category": "Edit Category",
      "Search CEP": "Search CEP",
      "# SIAPP available here.": "# SIAPP available here.",
      "Health Service Number": "Health Service Number",
      "Certificate: Attach the health certificate": "Certificate: Attach the health certificate",
      "Health Certificate": "Health Certificate",
      "Regional Innovation": "Regional Innovation",
      "Duplicate": "Duplicate",
      "Category duplicated successfully.": "Category duplicated successfully.",
      "Download Lab Analysis": "Download Lab Analysis",
      "Attach Analysis": "Attach Analysis",
      "Receive": "Receive",
      "Receive Product": "Receive Product",
      "Validate Product": "Validate Product",
      "Separate Product": "Separate Product",
      "In Analysis": "In Analysis",
      "Health Service": "Health Service",
      "Regional Innovation": "Regional Innovation",
      "Lab Analyst": "Lab Analyst",
      "Inspect Product": "Inspect Product",
      "Send to Analysis": "Send to Analysis",
      "Product approved!": "Product approved!",
      "Product dispproved!": "Product dispproved!",
      "Product sent to analysis!": "Product sent to analysis!",
      "Judge approved.": "Judge approved.",
      "Judge disapproved.": "Judge disapproved.",
      "Password reseted successfully!": "Password reseted successfully!",
      "Send Product": "Send Product",
      "Transfer Product": "Transfer Product",
      "Product sent successfully!": "Product sent successfully!",
      "Receive Transfered Product": "Receive Transfered Product",
      "Product transfered successfully!": "Product transfered successfully!",
      "Analysed": "Analysed",
      "Terms of Service and Privacy Policy": "Terms of Service and Privacy Policy",
      "By continuing, you agree to the Terms of Service and Privacy Policy.": "By continuing, you agree to the Terms of Service and Privacy Policy.",
      "I agree": "I agree",
      "I've read and agreed to the terms and privacy policy": "I've read and agreed to the terms and privacy policy",
      "Profile": "Profile",
      "Attach the cheese's analysis": "Attach the cheese's analysis",
      "The file is required": "The file is required",
      "View Analysis": "View Analysis",
      "Select All": "Select All",
      "List every category that applies to this sheet.": "List every category that applies to this sheet.",
      "Question Order": "Question Order",
      "New Review Sheet": "New Review Sheet",
      "Review Sheet added successfully.": "Review Sheet added successfully.",
      "Edit Review Sheet": "Edit Review Sheet",
      "Review Sheet updated successfully.": "Review Sheet updated successfully.",
      "The sheet must have at least one question.": "The sheet must have at least one question.",
      "Tables shuffled successfully.": "Tables shuffled successfully.",
      "Print Sample Submission Procedure": "Print Sample Submission Procedure",
      "Your registation is in analysis.": "Your registation is in analysis.",
      "Contest running, you cannot edit the review sheet's questions.": "Contest running, you cannot edit the review sheet's questions.",
      "Remarks": "Remarks",
      "Reset Password": "Reset Password",
      "History": "History",
      "Attach Product Analysis": "Attach Product Analysis",
      "Analysis attached successfully!": "Analysis attached successfully!",
      "Photographic Record": "Photographic Record",
      "View Record": "View Record",
      "TypeError: Cannot read properties of undefined (reading 'url')": "The file is required",
      "Fora do período de inscrição.": "Registrations already closed.",
      "Registrations closed": "Registrations closed",
      "TypeError: Cannot read properties of undefined (reading 'id')": "Invalid code.",
      "Usuário não encontrado!": "User not found.",
      "Generate Certificates": "Generate Certificates",
      "First Position": "First Position",
      "Second Position": "Second Position",
      "Third Position": "Third Position",
      "Position Award": "Position Award",
      "Choose the position to print all certificates regarding that position": "Choose the position to print all certificates regarding that position",
      "Print Certificates in Lot": "Print Certificates in Lot",
      "Training": "Training",
      "Training Mode": "Training Mode",
      "Product Review Report Release Date": "Product Review Report Release Date",
      "Product Subscription Limit by Producer": "Product Subscription Limit by Producer",
      "If there is no limit, leave this field blank.": "If there is no limit, leave this field blank.",
      "Allow Same Producer Award": "Allow Same Producer Award",
      "Minimal Grade to be Awarded": "Minimal Grade to be Awarded",
      "Generate Training QR Codes": "Generate Training QR Codes",
      "registration": "registration",
      "Export Cheese Receipt Report": "Export Cheese Receipt Report",
      "Scan Product": "Scan Product",
      "Table Code": "Table Code",
      "Curation": "Curation",
      "Consultant Analysis": "Consultant Analysis",
      "Download Award Seal": "Download Award Seal",
      "Judge Certificate Release Date": "Judge Certificate Release Date",
      "Reviewed": "Reviewed",
      "Download Consultant Analysis": "Download Consultant Analysis",
      "Attach the product's analysis": "Attach the product's analysis",
    },
  },
  es: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bien venido(a)",
      "We've missed you": "Tenga un lindo día.",
    },
  },
  pt: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Bem vindo(a)",
      "Yes": "Sim",
      "No": "Não",
      "Dashboard": "Painel de Controle",
      "We've missed you": "Tenha um ótimo dia.",
      "Users": "Usuários",
      "User": "Usuário",
      "user": "usuário",
      "judge": "jurado",
      "producer": "produtor",
      "Edit User": "Editar Usuário",
      "Edit Producer": "Editar Produtor",
      "Edit Judge": "Editar Jurado",
      "Add User": "Adicionar Usuário",
      "Add Producer": "Adicionar Produtor",
      "Add Judge": "Adicionar Jurado",
      "Name": "Nome",
      "Full Name": "Nome Completo",
      "Lastname": "Sobrenome",
      "E-mail": "E-mail",
      "Language": "Idioma",
      "Languages": "Idiomas",
      "Role": "Função",
      "Actions": "Ações",
      "New User": "Novo Usuário",
      "Save": "Salvar",
      "Save Changes": "Salvar Alterações",
      "Producer": "Produtor",
      "Judge": "Jurado",
      "Judges": "Jurados",
      "Admin": "Administrador",
      "Password": "Senha",
      "Confirm Password": "Confirmar Senha",
      "The password must have at least 6 characters.": "A senha deve conter ao menos 6 caracteres.",
      "Portuguese": "Português",
      "English": "Inglês",
      "Spanish": "Espanhol",
      "Italian": "Italiano",
      "French": "Francês",
      "Close": "Fechar",
      "Occured an Error": "Ocorreu um erro",
      "Done": "Concluir",
      "User registred successfully": "Usuário cadastrado com sucesso",
      "Required field": "O campo é obrigatório",
      "User updated successfully": "Usuário atualizado com sucesso",
      "Product registered successfully": "Produto inscrito com sucesso",
      "Registration updated successfully": "Inscrição atualizado com sucesso",
      "Farm's Name": "Nome da Fazenda / Queijaria",
      "Farm's Legal Representative": "Representante Legal da Fazenda",
      "Country": "País",
      "State": "Estado",
      "Status": "Status",
      "Active": "Ativo",
      "Inactive": "Inativo",
      "Producers": "Produtores",
      "Judges": "Jurados",
      "User agreed with the terms and Contest Regulation.": "Concordou com os termos, regulamento e edital do concurso.",
      "I have read and agreed to the Terms and Contest Regulation.": "Li e concordo com todos os termos, regulamento e edital do concurso.",
      "Every term and contest regulation have been agreed by the user.": "Todos os termos, regulamento e edital do concurso foram aprovados pelo usuário.",
      "Website": "Site",
      "Phone Number": "Telefone Fixo",
      "Cellphone Number": "Celular",
      "Document Number": "Número do Documento",
      "RG": "RG",
      "CPF": "CPF",
      "CNPJ": "CNPJ",
      "Passport": "Passaporte",
      "ID Document Type": "Tipo de Documento de Identificação",
      "City": "Cidade",
      "Zip-Code": "CEP",
      "This is the name that would be shown in your contest certificate.": "Nome que irá aparecer no certificado de premiação.",
      "Name of the property responsible.": "Nome do responsável da propriedade",
      "Producer updated successfully": "Produtor alterado com sucesso",
      "Judge updated successfully": "Jurado alterado com sucesso",
      "Producer registered successfully": "Produtor cadastrado com sucesso",
      "Judge registered successfully": "Jurado cadastrado com sucesso",
      "Curator updated successfully": "Curator alterado successfully",
      "Curator registered successfully": "Curator cadastrado successfully",
      "A confirmation e-mail was sent to the registered e-mail address.": "Um e-mail de confirmação foi enviado para o e-mail cadastrado.",
      "This producer needs to be activated by the user.": "Este produtor precisa ser ativado pelo usuário.",
      "This judge needs to be activated by the user.": "Este jurado precisa ser ativado pelo usuário.",
      "Producer Registration": "Cadastro de Produtor",
      "Judge Registration": "Cadastro de Jurado",
      "New Producer": "Novo Produtor",
      "New Judge": "Novo Jurado",
      "Subscribe another product": "Inscrever outro produto",
      "Register new product": "Inscrever novo produto",
      "Edit Registration": "Editar Inscrição",
      "Go to payment": "Ir para o pagamento",
      "Product's Name": "Nome do Produto",
      "Certificate Type": "Tipo de Certificação",
      "Municipal Certificate": "Municipal",
      "State Certificate": "Estadual",
      "Federal Certificate": "Federal",
      "Selo Arte": "Selo Arte",
      "Art Stamp": "Selo Arte",
      "Other": "Outro",
      "Certificate: Attach the products's certificate": "Certificado - Anexe o certificado do produto.",
      "Products's Characteristics": "Características do Produto",
      "Category": "Categoria",
      "Origin": "Origem",
      "Milk Type": "Tipo de Leite",
      "Raw": "Cru",
      "Pasteurized": "Pasteurizado",
      "Manufacturing Date": "Data de Fabricação",
      "Dough Treatment": "Tratamento da Massa",
      "Rind Type": "Tipo da Casca do Queijo",
      "Registrations": "Inscrições",
      "Registration": "Inscrição",
      "New Product Registration": "Nova Inscrição de Produto",
      "Certificate": "Certificado",
      "Code": "Código",
      "Paid": "Pago",
      "Registered Products": "Queijos Inscritos",
      "Products": "Produtos",
      "Orders": "Pedidos",
      "Orders History": "Histórico de Pedidos",
      "Reviews": "Avaliações",
      "Administrative": "Administrativo",
      "Brazil": "Brasil",
      "Italy": "Itália",
      "United States": "Estados Unidos",
      "France": "França",
      "Germany": "Alemanha",
      "Spain": "Espanha",
      "Approved": "Aprovado",
      "Disapproved": "Reprovado",
      "Pending": "Pendente",
      "Approval": "Aprovação",
      "Occupation": "Profissão",
      "Rows per page:": "Itens por página:",
      "of": "de",
      "more than": "mais que",
      "Proficiency Languages": "Idiomas de Domínio",
      "List every language you have proficiency.": "Liste todos os idiomas que você domina.",
      "Male": "Masculino",
      "Female": "Feminino",
      "Other": "Outro",
      "Nationality": "Nacionalidade",
      "Gender": "Sexo",
      "Educational Background": "Formação",
      "Professional Activity": "Atividade Profissional",
      "Company": "Empresa que Trabalha",
      "Product sensory analysis experience": "Experiência de análise sensorial de queijo",
      "I've never been a cheese contest judge": "Nunca fui jurado de concurso de queijo",
      "Are there any restrictions on judging a certain type of cheese?": "Há restrição para julgar algum tipo de queijo?",
      "Describe your dietary restriction": "Descreva a sua restrição alimentar",
      "Cart": "Carrinho",
      "Add File": "Adicionar Arquivo",
      "Delete": "Excluir",
      "Are you sure that you want to delete": "Tem certeza que deseja excluir o(a)",
      "deleted successfully!": "excluído com sucesso!",
      "Item deleted successfully!": "Exclusão realizada com sucesso!",
      "Cart": "Carrinho",
      "Description": "Descrição",
      "Amount": "Valor",
      "Go to payment": "Ir para o pagamento",
      "Items": "Itens",
      "Remove": "Remover",
      "Order Summary": "Resumo do Pedido",
      "Contest Parameters": "Parâmetros do Concurso",
      "Parameters updated successfully!": "Parâmetros atualizados com sucesso!",
      "Contest Registration End Date": "Data Final de Inscrição no Concurso",
      "Contest Subscripition Start Date": "Data Inicial de Inscrição no Concurso",
      "Payment Expiring Date": "Prazo de Vencimento do Boleto",
      "Product Registration Cost (R$)": "Valor da Inscrição do Produto (R$)",
      "Contest Registration Cost (R$)": "Valor da Inscrição no Concurso (R$)",
      "Logout": "Sair",
      "ID da Propriedade": "ID da Propriedade",
      "Passaporte": "Passaporte",
      "Approve": "Aprovar",
      "Disapprove": "Reprovar",
      "Success!": "Sucesso!",
      "Warning!": "Atenção!",
      "Invoices": "Faturamento",
      "You still didn't completed your registration in the Sebrae's 2024 Product Awards!": "Você ainda não concluiu a sua inscrição no Concurso Internacional do Queijo da Sebrae 2024!",
      "Finish you registration by clicking in the button below and make the payment of your registration.": "Faça o pagamento da sua inscrição clicando no botão abaixo.",
      "Order number": "Número do pedido",
      "Order total": "Total do pedido",
      "Thank you for your registration.": "Obrigada pela inscrição.",
      "Cash Credit Card": "Cartão de Crédito à vista",
      "Credit card in": "Cartão de Crédito em",
      "installments": "parcelas",
      "Pagamento realizado com sucesso!": "Pagamento realizado com sucesso!",
      "Credit Card": "Cartão de Crédito",
      "Payment method": "Escolha a forma de pagamento",
      "YOUR NAME": "SEU NOME",
      "Card Number": "Número do Cartão",
      "Card Name": "Nome do Cartão",
      "Valid Thru": "Validade",
      "Installments": "Parcelas",
      "Finish Order": "Finalizar Pedido",
      "This is a safe environment, payment made in encrypted environment.": "Este é um ambiente seguro, realizado em ambiente criptografato.",
      "Finish Payment": "Concluir Pagamento",
      "Válido até às": "Válido até às",
      "Brasilia time.": "horário de Brasília.",
      "Copy Pix Code": "Copiar Código Pix",
      "Payment Info": "Informações de pagamento",
      "Pix à vista": "Pix à vista",
      "Boleto à vista": "Boleto à vista",
      "Order": "Pedido",
      "Payment Status": "Status do Pagamento",
      "Payment Date": "Data de Pagamento",
      "Value": "Valor",
      "Pago": "Pago",
      "Vencida": "Vencida",
      "Aguardando pagamento": "Aguardando pagamento",
      "Expiring Date": "Data de Vencimento",
      "Expired in": "Vencida em",
      "Print": "Imprimir",
      "Payment No.": "Nº do Pagamento",
      "Hello,": "Olá,",
      "Este é o recibo do pagamento de": "Este é o recibo do pagamento de",
      "feito para Sebrae Brasil.": "feito para a Sebrae Brasil.",
      "to Sebrae Brasil.": "para Sebrae Brasil",
      "Este é o recibo vencido de um pedido de": "Este é o recibo vencido de um pedido de",
      "Este é o recibo de um pagamento pendente no valor de": "Este é o recibo de um pagamento pendente no valor de",
      "Order Completed": "Pedido Concluído",
      "Inscricao": "Inscrição do Concurso",
      "Produto": "Inscrição de Queijo",
      "Log In": "Acessar",
      "Forgot your password?": "Esqueci minha senha",
      "Sign up": "Novo usuário? Cadastre-se",
      "Remember me": "Lembrar-me",
      "Back": "Voltar",
      "Change my password": "Solicitar nova senha",
      "We just sent you the e-mail with the instructions to change your password.": "Enviamos as instruções de alteração de senha para o endereço de email associado à sua conta.",
      "Type your e-mail address to receive the instructions to setup your new password.": "Digite o seu e-mail para receber as instruções de definição de nova senha.",
      "Back to login": "Voltar para o Login",
      "Did not received the email?": "Não recebeu o e-mail?",
      "Check your trash or spam inboxes": "Verifique a sua caixa de spam e/ou lixeira",
      "Check you typed correctly your registred e-mail address": "O endereço de e-mail informado pode ter sido digitado com erros",
      "Make sure that the e-mail address is registred in our database": "Certifique-se que o endereço de e-mail informado possui cadastro no nosso sistema",
      "If you still have truble to access your account, contact our support.": "Se você ainda estiver com problemas para acessar a sua conta, contate o nosso suporte.",
      "Choose your role": "Escolha o tipo de cadastro que deseja",
      "I'm a...": "Sou um...",
      "Paying Method": "Forma de Pagamento",
      "CartaoCredito": "Cartão de Crédito",
      "Order Details": "Detalhe do Pedido",
      "Click on the link in this e-mail to validate your registration.": "Clique no link presente no e-mail para validar seu cadastro.",
      "Return to the login page": "Voltar para o login",
      "Experience details:": "Cite a sua experiência:",
      "Register": "Cadastrar",
      "Successful Registration": "Cadastro realizado com sucesso",
      "E-mail inválido": "E-mail inválido",
      "Campo obrigatório": "Campo obrigatório",
      "Type your new password below.": "Digite sua nova senha abaixo.",
      "New Password": "Nova Senha",
      "Password changed successfully": "Senha alterada com sucesso!",
      "Change password": "Alterar senha",
      "You must attach the certificate.": "Você deve anexar o certificado do produto.",
      "Cannot read properties of undefined (reading 'url')": "Você deve anexar o certificado do queijo.",
      "Do you really want to delete the file": "Deseja realmente apagar o arquivo",
      "Delete file": "Remover arquivo",
      "The certificate is required": "O certificado é obrigatório",
      "days": "dias",
      "Product Information": "Características do Produto",
      "Review Sheet": "Ficha de Avaliação",
      "Review Sheets": "Fichas de Avaliação",
      "Maturation Days": "Dias de Maturação",
      "Product's Code": "Código do Produto",
      "Estragado": "Estragado",
      "Péssimo": "Péssimo",
      "Ruim": "Ruim",
      "Insuficiente": "Insuficiente",
      "Razoável": "Razoável",
      "Suficiente": "Suficiente",
      "Bom": "Bom",
      "Ótimo": "Ótimo",
      "Excelente": "Excelente",
      "Perfeito": "Perfeito",
      "Send Review": "Enviar Avaliação",
      "General Aspect": "Aspecto Global",
      "Color": "Cor",
      "Texture": "Textura",
      "Odor": "Odor",
      "Scent": "Aroma",
      "Consistency": "Consistência",
      "Flavor": "Sabor",
      "Payment": "Pagamento",
      "Title / Aspect": "Título / Aspecto",
      "Title": "Título",
      "Aspect": "Aspecto",
      "Max Rating": "Nota Máxima",
      "Weight": "Peso",
      "Add New Evaluation Field": "Adicionar Novo Campo de Avaliação",
      "Click here to read the Terms and Contest Regulation.": "Clique aqui para ler o edital do concurso.",
      "Products": "Queijos",
      "Product": "Queijo",
      "Registration": "Inscrição",
      "Cancel": "Cancelar",
      "Add": "Adicionar",
      "The user read and agreed with the contest terms and regulation.": "O usuário leu e concordou com o edital do concurso.",
      "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.": "Por favor, verifique a sua caixa de entrada e, caso não encontre o e-mail, dê uma olhada na pasta de spam ou lixo eletrônico.",
      "User not found.": "Usuário não encontrado",
      "Usuário ou senha inválidos!": "Usuário ou senha inválidos!",
      "Queijo Maturado": "Queijo Maturado",
      "Queijo de Untar, Espalhável (Ex. de passar no pão)": "Queijo de Untar, Espalhável (Ex. de passar no pão)",
      "Ricotta": "Ricota",
      "Queijo Coalho": "Queijo Coalho",
      "Queijo de Manteiga": "Queijo de Manteiga",
      "Queijo Pastoso": "Queijo Pastoso",
      "Queijo de Massa Fundida Requeijão em Barra": "Queijo de Massa Fundida Requeijão em Barra",
      "Queijo de Massa Fundida Requeijão Moreno": "Queijo de Massa Fundida Requeijão Moreno",
      "Requeijão em Barra": "Requeijão em Barra",
      "Requeijão Moreno": "Requeijão Moreno",
      "Queijo com Mofo Azul": "Queijo com Mofo Azul",
      "Queijo Aromatizado e/ou Condimentado": "Queijo Aromatizado e/ou Condimentado",
      "Fresco com tratamento (mofo ou condimento)": "Fresco com tratamento (mofo ou condimento)",
      "Fresco sem tratamento": "Fresco sem tratamento",
      "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)": "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)",
      "Casca Florida (casca com predomínio de fungos filamentosos)": "Casca Florida (casca com predomínio de fungos filamentosos)",
      "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)": "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)",
      "Com Aquecimento": "Com Aquecimento",
      "Sem Aquecimento": "Sem Aquecimento",
      "Queijo Massa Filada": "Queijo Massa Filada",
      "Queijo Massa Não Filada": "Queijo Massa Não Filada",
      "Cru": "Cru",
      "Pausterizado": "Pausterizado",
      "Vaca": "Vaca",
      "Cabra": "Cabra",
      "Ovelha": "Ovelha",
      "Búfala": "Búfala",
      "Mistura de Diferentes Espécies": "Mistura de Diferentes Espécies",
      "Outro": "Outro",
      "Attention!": "Atenção!",
      "Event": "Evento",
      "Awards Info": "Informações do Concurso",
      "Email não confirmado!": "Email não confirmado!",
      "Resend account validation e-mail": "Reenviar email de confirmação",
      "There are no items in your cart.": "Não há itens no seu carrinho.",
      "Contest Phase": "Fase do Concurso",
      "Contest Phases": "Fases do Concurso",
      "Max Grade": "Nota Máxima",
      "Add question": "Adicionar nova questão",
      "Contest Start Date": "Dia de Início do Evento",
      "Contest": "Concurso",
      "Manufacturing Date cannot be after the event initial date.": "Data de Fabricação não pode ser após a data de início do evento.",
      "Payment Status": "Pagamento",
      "Processando pagamento": "Processando pagamento",
      "Não pago": "Não pago",
      "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.": "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.",
      "cheese": "queijo",
      "Add phase": "Adicionar fase",
      "Contest Phase Name": "Nome da Fase do Concurso",
      "Contest Phase Order": "Ordenação da Fase do Concurso",
      "Contest Tables": "Mesas do Concurso",
      "Shuffle Tables": "Sortear as mesas",
      "Contest Table ID Code": "Código de Identificação na Mesa",
      "Table Details": "Detalhes da Mesa",
      "Registrations by Producers": "Inscrições por Produtor",
      "Your Reviews": "Suas Avaliações",
      "New Curator": "Novo Curador",
      "Curator": "Curador",
      "Curators": "Curadores",
      "Edit Curator": "Editar Curador",
      "Makers With No Registrations": "Produtores Sem Inscrições",
      "Phone": "Telefone",
      "Pending Payment": "Pagamento Pendente",
      "Reports": "Relatórios",
      "Table": "Mesa",
      "Do you want to move": "Você deseja mover",
      "to which table?": "para qual mesa?",
      "Move judge to another table": "Mover jurado para outra mesa",
      "Free": "Isento",
      "Move": "Mover",
      "Judge moved successfully": "Jurado movido com sucesso!",
      "Product moved successfully": "Queijo movido com sucesso!",
      "Product reviewed successfully": "Queijo avaliado com sucesso!",
      "Jurado sem acesso ao queijo!": "Você não possui permissão para avaliar este queijo.",
      "Queijo já avaliado!": "Queijo já avaliado.",
      "Return": "Voltar",
      "Return to the previous page": "Voltar para página anterior",
      "Search": "Buscar",
      "Filter": "Filtro",
      "Table QR Codes": "QR Codes das Mesas",
      "Print Judge's List": "Imprimir Lista de Jurados",
      "Print Product's List": "Imprimir Lista de Produtos",
      "Print QR Codes": "Imprimir QR Codes",
      "Untie Priority": "Prioridade no Desempate",
      "Real Time": "Tempo Real",
      "Event in Real Time": "Evento em Tempo Real",
      "Start Phase": "Iniciar Fase",
      "End Phase": "Finalizar Fase",
      "Average Score": "Nota Média",
      "Reviews": "Avaliações",
      "Qtd. Reviewed": "Qtd. Avaliada",
      "Total Reviews": "Total de Avaliações",
      "Total Products": "Total de Produtos",
      "Total Judges": "Total de Jurados",
      "Fase iniciada com sucesso!": "Fase iniciada com sucesso!",
      "Fase finalizada com sucesso!": "Fase finalizada com sucesso!",
      "Phase already finished.": "Fase já concluída.",
      "Reset Phase": "Resetar Fase",
      "Phase": "Fase",
      "Your Score (%)": "Sua Pontuação (%)",
      'Average Score (%)': 'Nota Média (%)',
      'Winner (%)': 'Vencedor (%)',
      'Category Winner (%)': 'Vencedor da Categoria (%)',
      "Category Average Score (%)": "Média da Categoria (%)",
      "Overall Average Score (%)": "Média Geral (%)",
      "Overall Awards Results": "Classificação Geral",
      "Overall Result": "Classificação Geral",
      "Category Result": "Classificação na Categoria",
      "Score": "Pontuação",
      "Awarded": "Premiado",
      "Qualified to Next Phase": "Classificado para Próxima Fase",
      "Awards Results by Category": "Classificação por Categoria",
      "Export": "Exportar para Excel",
      "Classificados da 1ª fase": "Classificados para Segunda Fase",
      "Classificados da 2ª fase": "Classificados para Terceira Fase",
      "Qualified to Second Phase": "Classificados para Segunda Fase",
      "Qualified to Third Phase": "Classificados para Terceira Fase",
      "Qualified by Phase": "Classificados por Fase",
      "General": "Geral",
      "Second Phase": "Segunda Fase",
      "Approve to Second Phase": "Aprovar para próxima fase",
      "Judge approved to this contest phase!": "Jurado aprovado para esta fase!",
      "Products Qualified by Phase": "Produtos Classificados por Fase",
      "Tente novamente mais tarde.": "Tente novamente mais tarde.",
      "Refresh": "Atualizar",
      "Number of Products by Table": "Quantidade de Produtos por Mesa",
      "Number of Judges by Table": "Quantidade de Jurados por Mesa",
      "Selected": "Selecionado",
      "Not selected": "Não selecionado",
      "Deselect": "Desselecionar",
      "Jurado aprovado na fase": "Jurado aprovado na fase.",
      "Jurado reprovado na fase": "Jurado reprovado na fase.",
      "Quantitative Assessment Form": "Ficha de Avaliação Quantitativa",
      "Ratings from 0 to ": "Avaliações de 0 à ",
      "Rating from 0 to ": "Avaliação de 0 à ",
      "Selecionados": "Selecionados",
      "Select Judge": "Selecionar Jurado",
      "Add Judges - Phase": "Adicionar Jurados - Fase",
      "Export Grades": "Exportar com notas do concurso",
      "Reset Event": "Resetar Evento",
      "Reopen Phase": "Reabrir Fase",
      "Are you sure that you want to reopen this phase?": "Tem certeza que deseja reabrir essa fase?",
      "When you reopen a phase, you allow judges to submit new reviews or overwrite their past reviews.": "Quando você reabre uma fase, você permite que jurados enviem novas avaliações ou sobrescrevam avaliações já enviadas.",
      "Select a Category": "Selecione uma categoria",
      'Please wait, exporting data...': 'Aguarde, exportando dados...',
      "Are you sure that you want to re-order the contest tables?": "Tem certeza que deseja sortear as mesas do concurso?",
      "When you re-oder the tables, you change all cheeses and judges from its previous tables.": "Ao sortear as mesas, toda configuração de queijos e jurados posicionados anteriormente nas mesas será alterada.",
      "All the previous contest setup will be lost and a new one will be made.": "Toda configuração do concurso será perdida e uma nova configuração será gerada.",
      'Counting votes': 'Em apuração',
      "Heating": 'Aquecimento',
      "Heated": "Com aquecimento",
      "No heated": "Sem aquecimento",
      "Comment": "Comentário",
      "Table Leader": "Líder da Mesa",
      "Set Table Leader": "Atribuir Líder da Mesa",
      "Table Leader Setup": "Atribuição de Líder da Mesa",
      "Move Judge": "Mover Jurado",
      "Details": "Detalhes",
      "Are you sure that you want to change this table's leader to ": "Tem certeza que deseja alterar o líder da mesa para o(a) ",
      "Warning": "Atenção",
      "Table Leader changed successfully!": "Líder da mesa alterado com sucesso!",
      "Change Table Leader": "Alterar Líder da Mesa",
      "Scan QR Code": "Escanear QR Code",
      "Scan Another QR Code": "Escanear Outro QR Code",
      "Loading": "Carregando",
      "Product Status": "Estado",
      "Shipped": "Recebido",
      "Edit": "Editar",
      "Unknown": "Desconhecido",
      "Receive Product": "Receber Queijo",
      "Validate Product": "Validar Queijo",
      "Separate Product": "Separar Queijo",
      "Product received successfully!": "Produto recebido com sucesso!",
      "Product validated successfully!": "Produto validado com sucesso!",
      "Product separated successfully!": "Produto separado com sucesso!",
      "Able": "Apto",
      "Unable": "Inapto",
      "Print Certificate": "Imprimir Certificado",
      "Download Certificate": "Baixar Certificado",
      "Contest Started": "Concurso Iniciado",
      "Table Number": "Número da Mesa",
      "Current Contest Phase": "Número da Fase",
      "Contest not started yet.": "Concurso ainda não iniciado.",
      "Table's Leader Veredic": "Veredito do Líder da Mesa",
      "Grade": "Nota",
      "Print Registration Form": "Imprimir Ficha de Inscrição",
      "Page Not Found": "Página Não Encontrada",
      "The page you are looking for might have been removed.": "A página que você procura não existe ou foi removida.",
      "Return to the Home Page": "Voltar à página inicial",
      "Product Award Certificate Release Date": "Data de Disponibilização do Certificado de Premiação do Queijo",
      "Print Review Report": "Imprimir Relatório de Desempenho",
      "Reviews by Attributes": "Avaliação por Atributo",
      "Export Product Receipt Report": "Exportar Relatório de Recebimento de Queijos",
      "Add Product to the Table": "Adicionar Produto à Mesa",
      "Add Judge to the Table": "Adicionar Jurado à Mesa",
      "Move Product": "Mover Queijo",
      "Move product to another table": "Mover produto para outra mesa",
      "Add Judge to the Table": "Adicionar Jurado à Mesa",
      "Profession": "Profissão",
      "Registered Products": "Produtos Inscritos",
      "New Registration": "Nova Inscrição",
      "Awarded Product": "Produto Premiado",
      "Producer's Name": "Nome do Produtor",
      "Product Registration": "Inscrição de Produto",
      "User Profile Data": "Dados do Usuário",
      "Contest Categories": "Categorias do Concurso",
      "Add Category": "Adicionar Categoria",
      "Categories": "Categorias",
      "Category": "Categoria",
      "Category added successfully.": "Categoria adicionada com sucesso.",
      "Category Registration": "Cadastro de Categoria",
      "New Category": "Nova Categoria",
      "Category updated successfully.": "Categoria atualizada com sucesso.",
      "Edit Category": "Editar Categoria",
      "Product Categories": "Categorias de Produtos",
      "Search CEP": "Buscar CEP",
      "# SIAPP available here.": "Número do SIAPP disponível aqui.",
      "Health Service Number": "Número do Serviço Sanitário",
      "Certificate: Attach the health certificate": "Certificado - Anexe o certificado sanitário.",
      "Health Certificate": "Certificado Sanitário",
      "Regional Innovation": "Inovação Regional",
      "Duplicate": "Duplicar",
      "Category duplicated successfully.": "Categoria duplicada com sucesso.",
      "Download Lab Analysis": "Baixar Análise Laboratorial",
      "Attach Analysis": "Anexar Análise",
      "Receive": "Receber",
      "Receive Product": "Receber Produto",
      "Validate Product": "Validar Produto",
      "Separate Product": "Separar Produto",
      "In Analysis": "Colocar em Análise",
      "Health Service": "Serviço Sanitário",
      "Regional Innovation": "Inovação Regional",
      "Lab Analyst": "Analista Laboratorial",
      "Inspect Product": "Inspecionar Produto",
      "Send to Analysis": "Enviar para análise",
      "Product approved!": "Produto aprovado.",
      "Product dispproved!": "Produto reprovado.",
      "Product sent to analysis!": "Produto enviado para análise.",
      "Judge approved.": "Jurado aprovado.",
      "Judge disapproved.": "Jurado reprovado.",
      "Password reseted successfully!": "Senha resetada com sucesso.",
      "Send Product": "Enviar produto",
      "Transfer Product": "Transferir produto",
      "Product sent successfully!": "Produto enviado com sucesso!",
      "Receive Transfered Product": "Receber Transferência de Produto",
      "Product transfered successfully!": "Produto transferido com sucesso!",
      "Analysed": "Analisado",
      "Terms of Service and Privacy Policy": "Termos do Serviço e Política de Privacidade",
      "By continuing, you agree to the Terms of Service and Privacy Policy.": "Ao continuar com o cadastro, você concorda com os termos de serviço e com a nossa política de privacidade.",
      "I agree": "Concordo",
      "I've read and agreed to the terms and privacy policy": "Eu li e concordo com os termos e política de privacidade",
      "Profile": "Perfil",
      "Attach the cheese's analysis": "Anexe a análise do queijo",
      "The file is required": "Arquivo obrigatório",
      "View Analysis": "Ver Análise",
      "Select All": "Selecionar todos",
      "List every category that applies to this sheet.": "Selecione todas categorias que usarão esta ficha de avaliação.",
      "Question Order": "Ordem",
      "New Review Sheet": "Nova Ficha de Avaliação",
      "Review Sheet added successfully.": "Ficha de Avaliação cadastrada com sucesso.",
      "Edit Review Sheet": "Editar Ficha de Avaliação",
      "Review Sheet updated successfully.": "Ficha de Avaliação atualizada com sucesso.",
      "The sheet must have at least one question.": "A ficha deve ter pelo menos uma questão.",
      "Tables shuffled successfully.": "Mesas sorteadas com sucesso.",
      "Print Sample Submission Procedure": "Imprimir Procedimento de Envio de Amostra",
      "Your registation is in analysis.": "Seu cadastro está em análise. Em caso de dúvidas, contate a organizaçao.",
      "Contest running, you cannot edit the review sheet's questions.": "Concurso em andamento, as questões não podem ser editadas.",
      "Remarks": "Observações",
      "Reset Password": "Resetar Senha",
      "History": "Histórico",
      "Attach Product Analysis": "Anexar Análise do Produto",
      "Analysis attached successfully!": "Análise anexada com sucesso.",
      "Photographic Record": "Registro Fotográfico",
      "View Record": "Ver Registro",
      "TypeError: Cannot read properties of undefined (reading 'url')": "O arquivo é obrigatório.",
      "Fora do período de inscrição.": "Período de inscrições já encerrado.",
      "Registrations closed": "Inscrições encerradas",
      "TypeError: Cannot read properties of undefined (reading 'id')": "Código inválido.",
      "Usuário não encontrado!": "Usuário não encontrado!",
      "Generate Certificates": "Gerar Certificados",
      "First Position": "Primeiros Colocados",
      "Second Position": "Segundos Colocados",
      "Third Position": "Terceiros Colocados",
      "Position Award": "Posição de Premiação do Concurso",
      "Choose the position to print all certificates regarding that position": "Escolha a posição para imprimir todos certificados dos queijos premiados",
      "Print Certificates in Lot": "Imprimir Certificados em Lote",
      "Training": "Treinamento",
      "Training Mode": "Modo de Treinamento",
      "Product Review Report Release Date": "Data de Liberação do Relatório de Avaliação do Produto",
      "Product Subscription Limit by Producer": "Quantidade Máxima de Inscrições por Produtor",
      "If there is no limit, leave this field blank.": "Caso não haja limite de inscrições, deixar o campo vazio.",
      "Allow Same Producer Award": "Permitir Premiação do Mesmo Produtor nas Categorias",
      "Minimal Grade to be Awarded": "Nota Mínima para Premiação",
      "Generate Training QR Codes": "Gerar QR Codes de Treinamento",
      "registration": "inscrição",
      "Export Cheese Receipt Report": "Exportar Relatório de Recebimento de Queijos",
      "Scan Product": "Escanear Produto",
      "Table Code": "Código de Mesa",
      "Curation": "Curadoria",
      "Consultant Analysis": "Análise do Consultor",
      "Download Award Seal": "Baixar Selo de Premiação",
      "Judge Certificate Release Date": "Data de Disponibilização do Certificado do Jurado",
      "Reviewed": "Avaliado",
      "Download Consultant Analysis": "Baixar Análise do Consultor",
      "Attach the product's analysis": "Anexar análise do produto",
    },
  },
};

let token = "";

if (window.localStorage.getItem("accessToken")) {
  const accessToken = window.localStorage.getItem("accessToken");
  token = jwtDecode(accessToken);
}

// if (token.length != 0)
i18n.use(initReactI18next).init({
  resources,
  lng: token.idioma,
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
});

