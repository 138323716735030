import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { recuperacaoSenhaUsuario } from "../../api/usuarios";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
// import useAuth from "../../hooks/useAuth";
import FeedbackDialog from "../dialogs/FeedbackDialog";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function EsqueciMinhaSenha() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [open, setOpen] = useState(false);
  // const { resetPassword } = useAuth();

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const response = await recuperacaoSenhaUsuario(values)
      setSucesso(response.sucesso);

      setStatus({ sent: true });
      setSubmitting(false);
      openAlert();
    } catch (error) {
      setError(error.notificacoes[0].message);
      openAlert();
    }
  }

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const openAlert = () => {
    setOpen(true);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <FeedbackDialog
            open={open}
            onClose={handleClose}
            sucesso={sucesso}
            error={error}
            mensagem={
              <>
                <span>
                  {t("We just sent you the e-mail with the instructions to change your password.")}
                </span>
                <br />
                <span>{t("Did not received the email?")}</span>
                <ul style={{ textAlign: 'left', margin: '2em' }}>
                  <li>{t("Check your trash or spam inboxes")};</li>
                  <li>{t("Check you typed correctly your registred e-mail address")};</li>
                  <li>{t("Make sure that the e-mail address is registred in our database")}.</li>
                </ul>
                <span>
                  {t("If you still have truble to access your account, contact our support.")}
                </span>
              </>
            }
            closeBtnLabel={t("Back to login")}
            closeBtnAction={() => navigate("/login")}
          />

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    {t("Forgot your password?")}
                  </Typography>
                  <Typography sx={{ marginBottom: 5, marginTop: 5 }}>
                    {t("Type your e-mail address to receive the instructions to setup your new password.")}
                  </Typography>
                </div>
                <TextField
                  type="email"
                  name="email"
                  label="E-mail"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t("Change my password")}
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                  >
                    {t("Back")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}

export default EsqueciMinhaSenha;