import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { spacing } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Button = styled(MuiButton)(spacing);

function ValidacaoDialog({ open, sucesso, error, handleClose, onClick, message }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='alert-dialog'
    >
      {(open && sucesso && !error) &&
        <div>
          <DialogContent
            sx={{
              textAlign: 'center',
              margin: 10
            }}
          >
            <CheckCircleOutlineIcon
              color='success'
              sx={{
                fontSize: 100,
                marginBottom: 2
              }}
            />
            <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClick ? onClick : handleClose} variant="contained">
              {t("Close")}
            </Button>
          </DialogActions>
        </div>
      }

      {(open && error) && (
        <div>
          <DialogContent
            sx={{
              textAlign: 'center',
              margin: 10
            }}
          >
            <CancelOutlinedIcon
              color='error'
              sx={{
                fontSize: 100,
                marginBottom: 2
              }}
            />
            <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
              {t("Occured an Error")}<br />
              {t(error)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t("Close")}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}

export default ValidacaoDialog;