export const CpfValido = (cpf) => {
  var Soma = 0
  var Resto

  var strCPF = String(cpf).replace(/[^\d]/g, '')
  
  if (strCPF.length !== 11)
     return false
  
  if ([
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    ].indexOf(strCPF) !== -1)
    return false

  for (var i=1; i<=9; i++)
    Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);

  Resto = (Soma * 10) % 11

  if ((Resto == 10) || (Resto == 11)) 
    Resto = 0

  if (Resto != parseInt(strCPF.substring(9, 10)) )
    return false

  Soma = 0

  for (var i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)

  Resto = (Soma * 10) % 11

  if ((Resto == 10) || (Resto == 11)) 
    Resto = 0

  if (Resto != parseInt(strCPF.substring(10, 11) ) )
    return false

  return true
}

export const CnpjValido = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length != 14)
      return false;

  var tamanhoTotal = cnpj.length - 2
  var cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
  var digitosVerificadores = cnpj.substring(tamanhoTotal);
  var soma = 0;
  var pos = tamanhoTotal - 7;
  for (var i = tamanhoTotal; i >= 1; i--) {
      soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
      if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitosVerificadores.charAt(0))
      return false;

  tamanhoTotal = tamanhoTotal + 1;
  cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
  soma = 0;
  pos = tamanhoTotal - 7;
  for (var i = tamanhoTotal; i >= 1; i--) {
      soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
      if (pos < 2)
          pos = 9;
  }

  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitosVerificadores.charAt(1))
      return false;

  return true;
}