import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
    Tooltip,
    Menu,
    MenuItem,
    IconButton as MuiIconButton,
    Typography,
    Button,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BandeiraBrasil from '../../images/flags/br.png';
import BandeiraEUA from '../../images/flags/us.png';
import BandeiraEspanha from '../../images/flags/es.png';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
//   border-radius: 50%;
  width: 32px;
  height: 22px;
`;

const languageOptions = {
    pt: {
        icon: BandeiraBrasil,
        name: "Português",
    },
    en: {
        icon: BandeiraEUA,
        name: "English",
    },
    // es: {
    //     icon: BandeiraEspanha,
    //     name: "Español",
    // },
}

function NavbarLanguagesDropdown() {
    const { t, i18n } = useTranslation();
    const [anchorMenu, setAnchorMenu] = React.useState(null);

    const selectedLanguage = languageOptions[i18n.language];

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        closeMenu();
    };

    return (
        <React.Fragment>
            <Tooltip title={t("Languages")}>
                {selectedLanguage ?
                    <IconButton
                        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        onClick={toggleMenu}
                        color="inherit"
                        size="large"
                    >
                        <Flag src={selectedLanguage?.icon} alt={selectedLanguage?.name} />
                    </IconButton>
                    :
                    <Button
                        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        onClick={toggleMenu}
                        sx={{
                            // border: '1px solid #ccc',
                            // background: '#fff',
                            // padding: '5px 2px 5px 8px',
                            // width: '95px',
                            color: '#999'
                        }}
                        size="large"
                    >
                        <Typography>
                            {t("Language")}
                            <ArrowDropDownIcon />
                        </Typography>
                    </Button>
                }
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                {Object.keys(languageOptions).map((language) => (
                    <MenuItem
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                    >
                        {languageOptions[language].name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment >
    );
}

export default NavbarLanguagesDropdown;
