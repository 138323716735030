import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Button as MuiButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { spacing } from "@mui/system";
import WarningIcon from '@mui/icons-material/Warning';

const Button = styled(MuiButton)(spacing);

function PagamentoDialog({ open, handleClose }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="pagamento-dialog-title"
            className='pagamento-dialog'
        >
            <div>
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        margin: 10
                    }}
                >
                    <WarningIcon
                        color='error'
                        sx={{
                            fontSize: 100,
                            marginBottom: 2
                        }}
                    />
                    <DialogContentText sx={{ fontSize: 32, color: '#000' }}>
                        {t("Attention!")}
                    </DialogContentText>
                    <br />
                    <DialogContentText sx={{ fontSize: 16, color: '#000' }}>
                        {t("You still didn't completed your registration in the Sebrae's 2024 Product Awards!")} <br /><br />
                        {t("Finish you registration by clicking in the button below and make the payment of your registration.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t("Done")}
                    </Button>
                    <Button onClick={() => navigate("/carrinho")}>
                        {t("Go to payment")}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default PagamentoDialog;