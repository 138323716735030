import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import HomeIcon from '@mui/icons-material/Home';

const pagesSection = [
    {
        href: "/",
        icon: HomeIcon,
        title: "Home",
        key: 'dashboard',
    },
];

const produtorSection = [
    {
        href: "/inscricoes",
        icon: PieChartOutlineOutlinedIcon,
        title: "Registrations",
        key: 'inscricoes',
    },
    // {
    //     href: "/faturamento",
    //     icon: ReceiptOutlinedIcon,
    //     title: "Orders History",
    //     key: 'pedidos',
    // },
];

let navItems = [
    // {
    //     // title: "Pages",
    //     pages: pagesSection,
    // },
    {
        title: "Producer",
        pages: produtorSection,
    },
];

export default navItems;