import { Global, css } from "@emotion/react";
import BackgroundCertificadoJurado from "../images/certificados/senai/certificado_jurado.jpg";
import BackgroundCertificadoQueijoSuperOuro from "../images/certificados/expoqueijo/certificado_superouro.jpg";
import BackgroundCertificadoQueijoOuro from "../images/certificados/senai/certificado_ouro.jpg";
import BackgroundCertificadoQueijoPrata from "../images/certificados/senai/certificado_prata.jpg";
import BackgroundCertificadoQueijoBronze from "../images/certificados/senai/certificado_bronze.jpg";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      // Mobile Fix
      input[type="color"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="email"],
      input[type="month"],
      input[type="number"],
      input[type="password"],
      input[type="search"],
      input[type="tel"],
      input[type="text"],
      input[type="time"],
      input[type="url"],
      input[type="week"],
      select:focus,
      textarea {
        font-size: 13px;
      }

      @media screen and (-webkit-min-device-pixel-ratio:0) { 
        select,
        textarea,
        input {
          font-size: 13px;
        }
      }
      // /Mobile Fix

      button.MuiButton-root,
      a.MuiButton-root {
        margin: 5px;
      }

      .actions .MuiSvgIcon-root {
        fill: #6b6b6b;
      }

      .MuiDataGrid-row:hover,
      button:hover {
       transition: 0.3s;
      }

      a.MuiButton-contained:hover {
        color: #fff;
        transition: 0.3s;
      }

      .password-input .MuiInputBase-input {
        text-transform: unset;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: rgb(57 73 171);
          transition: 0.3s;
      }

      .MuiDataGrid-toolbarContainer {
        margin: 10px 10px 0;
      }

      body > iframe {
        pointer-events: none;
      }

      a {
        margin: 5px 0;
      }
      a:hover {
        color: #3949ab;
        background-color: rgb(37 138 221 / 15%);
        transition: 0.3s;
      }

      .escolha-tipo-usuario a:hover {
        color: #fff;
        transition: 0.3s;
      }

      button.MuiButton-contained svg,
      button.MuiButton-outlined svg,
      a.MuiButton-contained svg,
      a.MuiButton-outlined svg {
        margin-right: 5px;
      }

      button:focus {
        outline: none;
      }

      button.hideAction,
      a.hideAction {
        display: none;
      }

      .action-dialog .MuiPaper-root {
        max-width: unset;
      }

      .hzero-brand {
        bottom: 0;
        max-width: 100% !important;
        justify-content: center !important;
      }

      // @media (min-width: 1080px) {
      //   .authLogo {
      //     height: 100px;
      //   }
      // }

      p.MuiTablePagination-selectLabel,
      p.MuiTablePagination-displayedRows {
        margin-top: unset;
        margin-bottom: unset;
      }

      .lista-impressao .bordered-table td {
        border: 1px solid;
        padding: 5px;
      }

      .lista-impressao .dashed-table tr {
        border-style: dashed;
        padding: 5px;
      }

      .classificacao-chip span,
      .classificacao-chip svg {
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: bold;
      }

      .MuiTableCell-root .MuiBox-root {
        display: flex;
        float: right;
      }

      .judge-select .MuiDialog-paper {
        max-width: 1300px;
      }

      .filtro .MuiFormControl-root {
        margin-bottom: unset;
        margin-top: unset;
      }

      .certificado {
        font-family: 'GillSans';
        padding: 30px;
        width: 1200px;
        margin-top: 20px;
        height: 800px;
        box-shadow: 10px 5px 25px #bcbcbc;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 45%;
        font-weight: lighter;
      }

      .certificado.jurado {
        background-image: url(${BackgroundCertificadoJurado});
      }

      .certificado.queijo.super-ouro {
        background-image: url(${BackgroundCertificadoQueijoSuperOuro});
      }

      .certificado.queijo.super-ouro .titulo-certificado,
      .certificado.queijo.super-ouro .classificacao-certificado {
        display: none;
      }

      .certificado.queijo.super-ouro .nome-queijo {
        margin-top: 170px;
      }

      .certificado.queijo.ouro {
        background-image: url(${BackgroundCertificadoQueijoOuro});
      }

      .certificado.queijo.prata {
        background-image: url(${BackgroundCertificadoQueijoPrata});
      }

      .certificado.queijo.bronze {
        background-image: url(${BackgroundCertificadoQueijoBronze});
      }

      .certificado p {
        margin: 0;
      }

      .certificado .corpo-certificado {
        font-size: 1.25rem;
        width: inherit;
        text-align: center;
      }

      .certificado.jurado .corpo-certificado {
        margin-top: 180px;
      }

      .certificado.queijo .corpo-certificado {
        margin-top: 385px;
        margin-left: 525px;
        width: 450px;
      }

      .certificado.queijo .categoria-certificado {
        font-weight: 900;
        font-size: 1.3em;
        width: 450px;
        position: relative;
        text-transform: uppercase;
        line-height: normal;
      }

      .certificado.queijo .categoria-certificado .categoria {
        color: gold;
      }

      .certificado.queijo .categoria-certificado .nome-categoria {
        color: #303f9f;
      }

      .certificado .titulo-certificado {
        line-height: normal;
        font-size: 2.8rem;
        font-weight: bold;
      }

      .certificado.queijo .titulo-certificado {
        font-size: 3rem;
      }

      .certificado .classificacao-certificado {
        line-height: normal;
        font-size: 2.1rem;
        margin-top: 10px;
      }

      .certificado .classificacao-ingles {
        margin-left: 10px;
        font-weight: 300;
      }

      .certificado .nome-certificado {
        margin-top: 25px;
        font-size: 2.8rem; 
      }

      .certificado .nome-fazenda {
        font-size: 1.2rem; 
        position: relative;
        width: 450px;
        margin-top: 20px;
      }

      .certificado .data-evento {
        font-size: 1rem; 
        position: absolute;
        width: 450px;
        margin-top: 40px;
      }

      .certificado .nome-queijo {
        margin-top: 25px;
        position: relative;
        width: 450px;
        font-size: 1.6rem;
        text-transform: uppercase;
      }

      .certificado .nome-queijo.categoria-grande {
        margin-top: 25px;
      }

      .certificado.jurado .nome-certificado {
        text-transform: uppercase; 
      }

      .certificado .descricao-certificado {
        margin-top: 25px;
        width: 100%;
        text-align: center;
      }

      .certificado.queijo .descricao-certificado {
        font-family: 'GillSans';
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 230px;
        position: absolute;
        width: 450px;

      }

      .dashboard button,
      .dashboard a[class^="MuiButton"] {
        width: 100%
      }

      @media (max-width: 576px) {
        .dashboard button,
        .dashboard a[class^="MuiButton"] {
          height: 100px;
          font-size: 1.5rem;
        }
        .dashboard button svg,
        .dashboard a[class^="MuiButton"] svg {
          font-size: 1.5rem;
        }
      }

      .relatorio-avaliacao .relatorio-info-produto {
        margin-top: 30px;
        font-size: 14pt;
        width: 100%;
      }

      .menu-icon {
        height: 20px;
        width: 20px;
        display: block;
        margin: auto;
        -webkit-filter: invert(1);
        filter: invert(1);
      }
      .botao-buscar-cep {
        margin-top: unset !important;
        height: 100%;
      }
      .MuiDialog-container .MuiPaper-root {
        max-width: unset;
      }
        
      .datagrid {
        // background: #282728;
        background: #fff;
        height: 73vh !important;
      }
      // .datagrid.custom-height-grid {
      //   min-height: 52px;
      // }
      .datagrid .MuiDataGrid-main>div:last-child,
      .datagrid-avaliacao .MuiDataGrid-main>div:last-child,
      .datagrid-fases-concurso .MuiDataGrid-main>div:last-child {
          display: none;
      }
      .datagrid .MuiTablePagination-root p {
        margin-bottom: 0;
      }
      .datagrid MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
      }
      .alert-dialog .MuiDialog-paper p{
        margin-top: unset;
      }
      .css-axafay-MuiDataGrid-virtualScroller {
        min-height: 70px;
      }
      .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
        // border-right: 1px solid #515151;
        // border-bottom: 1px solid #515151;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }
      .MuiDataGrid-row--editing .MuiDataGrid-cell {
        background-color: #ebebeb !important;
      }
      .MuiDataGrid-row--editing .MuiDataGrid-cell--editing,
      .MuiDataGrid-row--editing .MuiDataGrid-cell--withRenderer {
        background-color: #fff !important;
      }
      .Mui-checked+.MuiSwitch-track {
        background-color: #35cd38 !important;
      }
      .MuiSwitch-switchBase .MuiSwitch-thumb {
        color: #fff;
      }
      .cor-valor {
        color: #000;
      }
      .cor-valor.positivo {
        color: #3dcd13;
      }
      .cor-valor.negativo {
        color: #ff0000;
      }
      .cor-valor.positivo {
        color: #3dcd13;
      }
      .development_env {
        border: 5px solid red;
      }
    `}
  />
);

export default GlobalStyle;
