import axios from "../utils/axios";

export function listarProdutores() {
    return axios.get(`/api/produtores`);
}

export function listarProdutoresPaginado(pagina, registroPagina) {
    return axios.get(`/api/produtores/paginado?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

export function obterProdutor(id) {
    return axios.get(`/api/produtores/${id}`);
}

export function inserirProdutor(data) {
    return axios.post(`/api/produtores`, data)
        .catch(function (error) {
            throw error;
        });
}

export function atualizarProdutor(id, data) {
    return axios.put(`/api/produtores/${id}`, data)
        .catch(function (error) {
            throw error;
        });
}

export function deletarProdutor(id) {
    return axios.delete(`/api/produtores/${id}`);
}

export function verificarDocumentoProdutor(documento) {
    return axios.get(`/api/produtores/documentos/${documento}`);
}