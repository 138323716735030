import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { alteracaoSenhaUsuario } from "../../api/usuarios";
import {
  Alert as MuiAlert,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
// import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import SenhaInput from "../../components/forms/Senha/SenhaInput";
import FeedbackDialog from "../dialogs/FeedbackDialog";

const Alert = styled(MuiAlert)(spacing);

function AlteracaoSenha() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [open, setOpen] = useState(false);
  // const { resetPassword } = useAuth();

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const params = new URLSearchParams(location.search)

      // debugger;
      await alteracaoSenhaUsuario(values, params.get("email"), params.get("token"))

      setSucesso(true);
      setStatus({ sent: true });
      setSubmitting(false);
      setOpen(true);
    } catch (error) {
      setStatus({ sent: false });
      setError(error.Notificacoes[0].Message);
      setSubmitting(false);
      setOpen(true);
    }
  }

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <Formik
      initialValues={{
        senha: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        senha: Yup.string()
          .required("Campo obrigatória")
          .min(6, 'Senha curta demais - deve conter ao menos 6 caracteres.'),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <FeedbackDialog
            open={open}
            onClose={handleClose}
            sucesso={sucesso}
            error={error}
            mensagem={t("Password changed successfully")}
            closeBtnLabel={t("Return to the login page")}
            closeBtnAction={() => navigate("/login")}
          />

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    {t("New Password")}
                  </Typography>
                  <Typography sx={{ marginBottom: 5, marginTop: 5 }}>{t("Type your new password below.")}</Typography>
                </div>
                <SenhaInput
                  id="senha"
                  name="senha"
                  label={t("Password")}
                  value={values.senha}
                  error={Boolean(touched.senha && errors.senha)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText>
                  {t("The password must have at least 6 characters.")}
                </FormHelperText>

                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t("Change password")}
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                  >
                    {t("Back")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}
export default AlteracaoSenha;