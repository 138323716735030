import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SpeedIcon from '@mui/icons-material/Speed';

const pagesSection = [
  {
    href: "/",
    icon: SpeedIcon,
    title: "Home",
    key: 'dashboard',
  },
];

const adminSection = [
  // {
  //   href: "/jurados",
  //   icon: VerifiedOutlinedIcon,
  //   title: "Judges",
  //   key: 'jurados',
  // },
  {
    href: "/inscricoes",
    icon: PieChartOutlineOutlinedIcon,
    title: "Registrations",
    key: 'inscricoes',
  },
];

const eventSection = [
  {
    href: "/mesas",
    icon: TableViewIcon,
    title: "Contest Tables",
    key: 'mesas',
  },
  {
    href: "/tempo-real",
    icon: RestoreIcon,
    title: "Event in Real Time",
    key: 'tempo-real',
  },
  {
    href: "/classificacao-geral",
    icon: EmojiEventsIcon,
    title: "Overall Awards Results",
    key: 'classificacao-geral',
  },
  {
    href: "/classificacao-categoria",
    icon: MilitaryTechIcon,
    title: "Awards Results by Category",
    key: 'classificacao-categoria',
  },
];

const juradoSection = [
  {
    href: "/avaliacoes",
    icon: RateReviewOutlinedIcon,
    title: "Reviews",
    key: 'avaliacoes',
  },
];

let navItems = [
  {
    // title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Administrative",
    pages: adminSection,
  },
  {
    title: "Event",
    pages: eventSection,
  },
];

export default navItems;