import React from 'react';
import { Button, Typography } from "@mui/material";
import FormularioProdutor from './Formularios/FormularioProdutor';
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { ArrowBack } from '@mui/icons-material';

function CadastroProdutor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isFullWidth = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Button variant='contained' onClick={() => navigate(-1)} fullWidth={isFullWidth}>
        <ArrowBack/>{t('Back')}
      </Button> 
      <Typography
        component="h3"
        variant="h3"
        align="center"
        sx={{
          marginBottom: 10,
          marginTop: 10
        }}
      >
        {t("Producer Registration")}
      </Typography>
      <FormularioProdutor />
    </React.Fragment>
  );
}

export default CadastroProdutor;